export function nextAttachmentForMinorStep() {
  return {
      type: 'NEXT_ATTACHMENT_FOR_MINOR_STEP'
  }
}

export function resetAttachmentForMinorStep() {
  return {
      type: 'RESET_ATTACHMENT_FOR_MINOR_STEP'
  }
}