const initialState = {
  isOpen: false,
  isCompleted: false,
  lastEmail: '',
  attemps: 0,
  lastAttemp: null,
}

export default function alterEmailStepForMine(state = initialState, action) {
  switch (action.type) {
    case 'ALTER_EMAIL_OPEN_DIALOG_STEP_FOR_MINE_CONFIRM_EMAIL':
      return {
        ...state,
        isOpen: action.isOpen,
      }
    case 'ALTER_EMAIL_COMPLETE_DIALOG_STEP_FOR_MINE_CONFIRM_EMAIL':
      return {
        ...state,
        isCompleted: action.isCompleted,
      }
    case 'ALTER_EMAIL_ADD_LAST_EMAIL_STEP_FOR_MINE_CONFIRM_EMAIL':
      return {
        ...state,
        lastEmail: action.lastEmail,
      }
    case 'ALTER_EMAIL_ADD_EMAIL_LIMIT_STEP_FOR_MINE_CONFIRM_EMAIL':
      return {
        ...state,
        attemps: state.attemps+1,
        lastAttemp: new Date().getTime()
      }
    case 'ALTER_EMAIL_RESET_EMAIL_LIMIT_STEP_FOR_MINE_CONFIRM_EMAIL':
      return {
        ...state,
        attemps: 0,
        lastAttemp: null
      }
    case 'ALTER_EMAIL_RESET_DATA_STEP_FOR_MINE_CONFIRM_EMAIL':
      return initialState
    default:
      return state;
  }
}