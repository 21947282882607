import { Mexx2DOConsole } from "../../utils/mexxTalkConsole"

const initialState = {
  calledservicesettings: null,
  steps: null
}

export default function calledServiceSettingsStep(state = initialState, action) {
  Mexx2DOConsole.log("=== Redux - calledServiceSettingsStep ===")
  Mexx2DOConsole.log(action)
  switch (action.type) {
    case 'SET_CALLED_SERVICE_SETTINGS_STEPS_DATA':
      return {
        ...state,
        calledservicesettings: action.calledservicesettings,
        steps: action.steps
      }
    case 'RESET_CALLED_SERVICE_SETTINGS_STEPS_DATA':
      return initialState
    default:
      return state;
  }
}