import { Mexx2DOConsole } from "../../utils/mexxTalkConsole"

const initialState = {
  isOpen: false,
  documentNifStep: 1,
  isCompleted: false
}

export default function documentRecognitionStep(state = initialState, action) {
  Mexx2DOConsole.log("=== Redux - documentRecognitionStep ===")
  Mexx2DOConsole.log(action)
  switch (action.type) {
    case 'OPEN_DIALOG_DOCUMENT_RECOGNITION_STEP':
      return {
        ...state,
        isOpen: action.isOpen,
      }
    case 'NEXT_DIALOG_DOCUMENT_RECOGNITION_STEP':
      return {
        ...state,
        documentNifStep: 2,
      }
    case 'COMPLETE_DIALOG_DOCUMENT_RECOGNITION_STEP':
      return {
        ...state,
        isCompleted: action.isCompleted,
      }
    case 'RESET_DATA_DIALOG_DOCUMENT_RECOGNITION_STEP':
      return initialState
    default:
      return state;
  }
}