import { Grid, Typography } from '@mui/material'
import React from 'react'
import TagIcon from '@mui/icons-material/Tag'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import GVFeedback from '../feedback'

export default function OpenedCalled({ protocol, openingDate }) {
  return (<Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <GVFeedback warning2>Atenção</GVFeedback>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" fontWeight="bold">Este CPF já possui uma solicitação registrada e em andamento</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <TagIcon />
      <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Protocolo:</Typography>
      <Typography variant="body1" component="span" >&nbsp;&nbsp;{protocol}</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <CalendarMonthIcon />
      <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Data de abertura:</Typography>
      <Typography variant="body1" component="span" >&nbsp;&nbsp;{openingDate}</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" >Você receberá uma resposta sobre a solicitação em sua caixa de e-mail em até 1 dia útil após a solicitação.</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" >Se não o encontrar, lembre-se de verificar também na sua pasta de lixo eletrônico e spam.</Typography>
    </Grid>
  </Grid>)
}