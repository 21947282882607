import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import Routes from './routes';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/store';
import { browserHistory } from './helpers/history';
import IntlProviderConfigured from './_i18n/intlproviderconfigured';
import './paginate.css';
import './styles/fonts/font-family.css'
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import awsexports from './config/aws-exports';
import { ThemeProvider } from './context/theme-context';
import { VwProvider } from './context/vw-context';

Amplify.configure(awsexports);

function App() {
    useEffect(() => {
      
    }, []);

    return (
        <PersistGate loading={null} persistor={persistor}>
            <VwProvider>
            <ThemeProvider>
                <Router history={browserHistory}>
                    <IntlProviderConfigured>
                        <Routes>
                        </Routes>
                    </IntlProviderConfigured>           
                </Router>
            </ThemeProvider>
            </VwProvider>
        </PersistGate>
    );
}

export default App;