import React, { useEffect } from 'react'
import { Divider, Grid, Typography } from '@mui/material';
import Alert, { alertClasses } from '@mui/material/Alert'
import * as componentActions from '../../../../redux/actions/alter-email-step-for-mine'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import GVFeedback from '../../../../components/feedback';
import GVAutocomplete from '../../../../components/gov-br/autocomplete';
import DocumentTypeInfo from './components/document-type-info';
import { useFormContext } from 'react-hook-form';
import GVFileUpload from '../../../../components/gov-br/upload';
import { withStyles } from '@mui/styles'
import { Styles } from '../../../../styles/material-styles'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import GVMessage from '../../../../components/gov-br/message';

const StepAttachmentMinor = React.memo(function StepAttachmentMinor(props) {
  const methods = useFormContext();
  const formValues = methods.watch()
  
  const [selectField, setSelectField] = React.useState(null)
  const [uploadField, setUploadField] = React.useState(null)
  const [attachmentList, setAttachmentList] = React.useState([])
  const [uploadFieldNIF, setUploadFieldNIF] = React.useState(null)
  const [attachmentListNIF, setAttachmentListNIF] = React.useState([])
  const [organizationFieldMajor, setOrganizationFieldMajor] = React.useState('')
  const [fileUploadField, setFileUploadField] = React.useState('')
  const [fileUploadFieldNIF, setFileUploadFieldNIF] = React.useState('')

  const attachmentForMinordocumentNifStep = useSelector(state => state.attachmentForMinorStep.documentStep);
  const stepsData = useSelector((state) => state.calledServiceSettingsStep.steps)
  const stepData = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 7).sort((a, b) => a.order - b.order) : []

  const completed = props.stepper.completed
  const isCompleted = completed?.[4] === true;

  useEffect(() => {
    if (stepData.length > 0) {
      let sField = stepData.find(element => element.fieldtype == 2 && element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype === 4 && element.calledservicesettingsorganizationfield?.label == "Anexo - Para menor")
      if (sField != null) {
        const fieldId = sField.calledservicesettingsorganizationfield?.id
        const options = sField.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
        const formattedOptions = formatOptions(options)
        sField.calledservicesettingsorganizationfield.organizationfield.organizationfieldoptionsvalues = formattedOptions
        setOrganizationFieldMajor(`ofield-${fieldId}`)
      }
      setSelectField(sField)
      let uField = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.label == "Anexo - Para menor")
      if (uField != null) {
        const fieldId = uField.calledservicesettingsfileupload?.id
        setFileUploadField(`ofield-${fieldId}`)
      }
      setUploadField(uField)
      let uFieldNIF = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.label == "Anexo - Para menor NIF")
      if (uFieldNIF != null) {
        const fieldId = uFieldNIF.calledservicesettingsfileupload?.id
        setFileUploadFieldNIF(`ofield-${fieldId}`)
      }
      setUploadFieldNIF(uFieldNIF)
    }
  }, [])

  useEffect(() => {
    if (isCompleted && fileUploadField.length > 0 && formValues[fileUploadField] && formValues[fileUploadField].length > 0) {
      if (attachmentList.length !== formValues[fileUploadField].length) {
        setAttachmentList(formValues[fileUploadField]);
      }
    }
  }, [isCompleted, formValues, attachmentList, fileUploadField])

  useEffect(() => {
    if (isCompleted && fileUploadFieldNIF.length > 0 && formValues[fileUploadFieldNIF] && formValues[fileUploadFieldNIF].length > 0) {
      if (attachmentListNIF.length !== formValues[fileUploadFieldNIF].length) {
        setAttachmentListNIF(formValues[fileUploadFieldNIF]);
      }
    }
  }, [isCompleted, formValues, attachmentListNIF, fileUploadFieldNIF])

  const formatOptions = (options, orderType = "id") => {
    if (options && options.length > 0)
      options.sort((a, b) => a[orderType] - b[orderType])
    else
      options = []
    return options
  }

  return (<React.Fragment>
    <Grid container spacing={2}>
      {isCompleted && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVMessage success>Esta etapa já foi concluída. Caso deseje alterar os dados, volte ao início</GVMessage>
      </Grid>}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <AttachFileIcon style={{ fontSize: 'var(--font-size-scale-up-02)' }} />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '5px', fontSize: 'var(--font-size-scale-up-02)' }} >Adicione o anexo necessário abaixo</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >Vamos precisar de um documento que comprove a condição de responsável legal pela criança ou adolescente. Selecione abaixo aquele que deseja utilizar.</Typography>
      </Grid>
      {(!isCompleted && selectField && attachmentForMinordocumentNifStep === 1) && (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVAutocomplete
          name={`ofield-${selectField.calledservicesettingsorganizationfield?.id}`}
          label={''}
          options={selectField.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues}
          placeholder="Selecione o tipo de documento a ser utilizado"
          fullWidth
          required />
      </Grid>)}
      {((isCompleted || attachmentForMinordocumentNifStep > 1) && organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{formValues[organizationFieldMajor].name}</Typography>
      </Grid>}
      {(organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name !== 'Selecione o tipo de documento a ser utilizado') && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVFeedback warning2>Cópias de documentos rasurados ou rasgados não serão aceitos</GVFeedback>
      </Grid>}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <DocumentTypeInfo inputName={organizationFieldMajor} />
      </Grid>
      {(organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name !== 'Selecione o tipo de documento a ser utilizado') && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Divider />
      </Grid>}
      {(!isCompleted && attachmentForMinordocumentNifStep === 1 && organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name !== 'Selecione o tipo de documento a ser utilizado') && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVFileUpload
          item={{
            id: uploadField?.calledservicesettingsfileupload?.id,
            description: "",
            label: '',
            filelimit: uploadField?.calledservicesettingsfileupload?.filelimit,
            calledservicesettingsfileuploadattachments: []
          }}
          classes={props.classes}
          classStyle={undefined} />
      </Grid>}
      {(!isCompleted && attachmentForMinordocumentNifStep > 1 && organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name !== 'Selecione o tipo de documento a ser utilizado') && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVFileUpload
          item={{
            id: uploadFieldNIF?.calledservicesettingsfileupload?.id,
            description: "",
            label: '',
            filelimit: uploadFieldNIF?.calledservicesettingsfileupload?.filelimit,
            calledservicesettingsfileuploadattachments: []
          }}
          classes={props.classes}
          classStyle={undefined} />
      </Grid>}
      {((isCompleted || attachmentForMinordocumentNifStep > 1) && organizationFieldMajor.length > 0 && formValues[organizationFieldMajor] && formValues[organizationFieldMajor].name && attachmentList.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <AttachFileIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Anexo:</Typography>
      </Grid>}
      {(isCompleted || attachmentForMinordocumentNifStep > 1) && attachmentList.map(attachment => {
        return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{attachment.name}</Typography>
      </Grid>)}
      )}
      {(isCompleted) && attachmentListNIF.map(attachment => {
        return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{attachment.name}</Typography>
      </Grid>)}
      )}
    </Grid>
  </React.Fragment>)
})

const mapStateToProps = state => ({
  classes: state.classes,
  stepper: state.stepper
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(Styles)(StepAttachmentMinor));