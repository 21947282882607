import React from 'react';
import LogoMarca from '../../images/logo-cmtech-login.png';

const Logo = props => (
    <div>
        <img width={props.width} src={LogoMarca} title="Logomarca" />
    </div>
);

export default Logo;
