import React from 'react'
import { Grid, Typography } from '@mui/material';
import * as componentActions from '../../../../redux/actions/alter-email-step-for-mine'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useFormContext } from 'react-hook-form';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TagIcon from '@mui/icons-material/Tag';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment';

const StepCompleted = React.memo(function StepCompleted(props) {
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const onOpenDialog = () => {
    setDialogOpen(true)
  }

  const onCloseDialog = () => {
    setDialogOpen(false)
  }

  const methods = useFormContext()
  const formValues = methods.watch()

  return (<React.Fragment>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <CheckCircleIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Solicitação enviada com sucesso!</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <TagIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Protocolo:</Typography>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;{props.createdCalled.calledId}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <CalendarMonthIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Data de abertura:</Typography>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;{moment(props.createdCalled.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >Você receberá uma resposta sobre a solicitação em sua caixa de e-mail em até 1 dia útil após a solicitação.</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >Se não encontrar, lembre-se de verificar também na sua pasta de lixo eletrônico ou spam.</Typography>
      </Grid>
    </Grid>
  </React.Fragment>)
})

const mapStateToProps = state => ({
  createdCalled: state.createdCalled
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StepCompleted);