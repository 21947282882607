import { Mexx2DOConsole } from "../../utils/mexxTalkConsole"

const initialState = {
  documentStep: 1,
}

export default function attachmentForMinorStep(state = initialState, action) {
  Mexx2DOConsole.log("=== attachmentForMinorStep ===")
  Mexx2DOConsole.log(action)
  switch (action.type) {
    case 'NEXT_ATTACHMENT_FOR_MINOR_STEP':
      return {
        ...state,
        documentStep: 2,
      }
    case 'RESET_ATTACHMENT_FOR_MINOR_STEP':
      return initialState
    default:
      return state;
  }
}