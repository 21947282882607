import { Mexx2DOConsole } from "../../utils/mexxTalkConsole"

const initialState = {
  completed: {},
  totalSteps: 0,
  allStepsCompleted: false
}

const stepper = (state = initialState, action) => {
  Mexx2DOConsole.log("=== Redux - calledServiceSettingsStep ===")
  Mexx2DOConsole.log(action)
  switch (action.type) {
    case 'SET_COMPLETED':
      const newCompleted = state.completed ?? initialState
      newCompleted[action.activeStep] = true
      return {
        ...state,
        completed: newCompleted
      }
    case 'SET_TOTAL_STEPS':
      return {
        ...state,
        totalSteps: action.totalSteps
      }
    case 'SET_COMPLETED_ALL_STEPS':
      return {
        ...state,
        allStepsCompleted: true
      }
    case 'RESET_STEPPER':
      return {
        completed: {},
        totalSteps: 0,
        allStepsCompleted: false
      }
    default:
      return state
  }
}

export default stepper