import { createTheme } from '@mui/material/styles';

import { palette } from './palette';
import typography from './typography';

const theme = createTheme({
  palette: palette,
  typography,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  },
  shape: {
    borderRadius: 8
  },
  breakpoints: {
      values: {
      xs: 0,
      sm: 600,
      mobile: 576,
      tablet: 768,
      md: 900,
      laptop: 992,
      lg: 1200,
      desktop: 1200,
      xl: 1536,
      tv: 1400
    }
  }
});

export default theme;
