import React from 'react';
import PropTypes from 'prop-types';
import '../../../App.css'
import '../../../styles/spacing/spacing.css'
import '../../../styles/fonts/font-size.css'
import { useFormContext } from 'react-hook-form';
import { StepperPannelContent } from './component-styles';

const GVStepperPannelContent = ({ activeStep, steps }) => {
  const methods = useFormContext();
  const formValues = methods.watch();
  const currentStep = steps[activeStep];

  if (!currentStep) {
    return null;
  }

  const { components } = currentStep;

  return (
    <StepperPannelContent>
      {components &&
        components.map(({ component: StepComponent, condition }, index) => (
          condition && condition(formValues) && (<StepComponent key={index} formValues={formValues} />)
        ))}
    </StepperPannelContent>
  );
};

GVStepperPannelContent.propTypes = {
  activeStep: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      components: PropTypes.arrayOf(
        PropTypes.shape({
          component: PropTypes.elementType.isRequired,
          condition: PropTypes.func.isRequired,
          completeCondition: PropTypes.func
        })
      ).isRequired,
    })
  ).isRequired,
};

export default GVStepperPannelContent;