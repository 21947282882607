import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { TextField, InputAdornment, FormControl } from '@mui/material';
import InputBase, { inputBaseClasses } from '@mui/material/InputBase'
import InputLabel, { inputLabelClasses } from '@mui/material/InputLabel'
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import typography from '../../../styles/typography';
import GVFeedback from '../../feedback';
import { cpfMask } from '../../../utils/cpfUtils';
import StringUtils from '../../../utils/stringUtils';
import { validateDocument, validateEmail } from '../../../pages/alter-account-email-test/components/step-for-mine/components/validations';
import FormLabel, { formLabelClasses } from '@mui/material/FormLabel'

const GVBRInputLabel = styled(FormLabel)(({ theme }) => {
  return ({
    ...typography.label,
  marginLeft: 0,
  paddingLeft: 0,
  marginBottom: '0!important',
    
  })
})

const GVBRInputBase = styled(InputBase)(({ theme }) => {
  return ({
  '--input-button-margin': 'var(--spacing-scale-half)',
  '--input-padding': '0 var(--spacing-scale-2x)',
  '--input-padding-button': 'var(--spacing-scale-5x)',
  '--input-padding-icon': 'var(--spacing-scale-5x)',
  '--input-size': 'var(--input-medium)',
  '--input-small': '32px',
  '--input-medium': '40px',
  '--input-large': '48px',
  '--input-highlight': '56px',
  '--color': theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color-light)',
  '--color-rgb': theme.palette.mode === 'dark' ? 'var(--color-dark-rgb)' : 'var(--color-light-rgb)',
  '--text-color': theme.palette.mode === 'dark' ? 'var(--color-dark)' : 'var(--color-light)',
  '--interactive': theme.palette.mode === 'dark' ? 'var(--interactive-dark)' : 'var(--interactive-light)',
  '--interactive-rgb': theme.palette.mode === 'dark' ? 'var(--interactive-dark-rgb)' : 'var(--interactive-light-rgb)',
  '--visited': theme.palette.mode === 'dark' ? 'var(--visited-dark)' : 'var(--visited-light)',
  '--hover': theme.palette.mode === 'dark' ? 'var(--hover-dark)' : 'var(--hover-light)',
  '--pressed': theme.palette.mode === 'dark' ? 'var(--pressed-dark)' : 'var(--pressed-light)',
  '--focus-color': theme.palette.mode === 'dark' ? 'var(--focus-color-dark)' : 'var(--focus-color-light)',
  '--focus': theme.palette.mode === 'dark' ? 'var(--focus-color-dark)' : 'var(--focus-color-light)',
  color: 'var(--color)',
  position: 'relative',
  'label + &': {
    marginRight: 'var(--spacing-scale-baseh)',
    marginTop: 'calc(var(--input-size) * 0.5 - var(--spacing-scale-half))'
  },
  [`& .${inputBaseClasses.input}`]: {
    backgroundColor: theme.palette.mode === 'dark' ? 'var(--pure-0)' : 'var(--background-light)',
    borderColor: 'var(--border-color-alternative)',
    borderRadius: 'var(--surface-rounder-sm)',
    borderStyle: 'var(--border-style)',
    borderWidth: 'var(--border-width)',
    color: 'var(--color-light)',
    display: 'block',
    fontSize: 'var(--font-size-scale-up-01)',
    fontWeight: 'var(--font-weight-medium)',
    height: 'var(--input-size)',
    marginTop: 'var(--spacing-scale-half)',
    paddingBottom: '0',
    paddingLeft: 'var(--spacing-scale-base)',
    paddingRight: 'var(--spacing-scale-base)',
    paddingTop: '0',
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? 'var(--pure-0)' : 'var(--gray-2)',
    }
  },
  [`&.${inputBaseClasses.error} input`]: {
    borderColor: 'var(--danger)'
  },
  [`&.${inputBaseClasses.focused} input`]: {
    borderColor: 'var(--focus) !important',
    boxShadow: '0 0 0 var(--surface-width-md) var(--focus)',
    outline: 'none'
  },
})})

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputLabel-root': {
    ...typography.label,
    transform: 'translate(14px, 12px) scale(1)',
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}));

const StyledIcon = styled('span')(({ theme }) => ({
  background: 'var(--background)',
  border: '1px solid var(--border-color)',
  borderRadius: '50%',
  content: '""',
  height: 'var(--radio-size)',
  left: 0,
  position: 'absolute',
  top: '-1px',
  width: 'var(--radio-size)',
  '&:hover': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
}));

const GVTextField = React.memo(function GVTextField({ name, label, placeholder, required, StartIcon, errorMessage, fullWidth, value, onChange, onCut, onCopy, onPaste, helperText, inputProps, icon, onBlur, type = "text", ...rest }) {
  const methods = useFormContext();
  const { control, formState: { errors }, clearErrors } = methods
  const formValues = methods.watch()

  if (type === "email") {
    return (
      <FormControl error={Boolean(errors && errors[name])} fullWidth={fullWidth} variant="standard">
        <GVBRInputLabel component="legend" shrink htmlFor={name}>
          {StartIcon && <StartIcon style={{ fontSize: 20, marginRight: 5 }} />}
          {label}
        </GVBRInputLabel>
        <Controller
          name={name}
          control={control}
          rules={ required ? { required: errorMessage ? errorMessage : 'O campo é obrigatório' } : {}}
          defaultValue=""
          render={({ field }) => (
              <GVBRInputBase
                {...field}
                autoComplete="password"
                aria-label={name}
                id={name}
                name={name}
                label={label}
                placeholder={placeholder}
                fullWidth={fullWidth}
                onChange={(e) => {
                  field.onChange(e)
                  validateEmail(methods, name, e.target.value)
                }}
                onBlur={onBlur}
                onCut={onCut}
                onCopy={onCopy}
                onPaste={onPaste}
                helperText={helperText}
                inputProps={inputProps} />
          )} />
        {required && errors && errors[name] && (
          <GVFeedback danger>{errors[name].message}</GVFeedback>
        )}
      </FormControl>
    );
  } else if (type === "document") {
    return (
      <FormControl error={Boolean(errors && errors[name])} fullWidth={fullWidth} variant="standard">
        <GVBRInputLabel component="legend" shrink htmlFor={name}>
          {StartIcon && <StartIcon style={{ fontSize: 20, marginRight: 5 }} />}
          {label}
        </GVBRInputLabel>
        <Controller
          name={name}
          control={control}
          rules={ required ? { required: errorMessage ? errorMessage : 'O campo é obrigatório' } : {}}
          defaultValue=""
          render={({ field }) => (
              <GVBRInputBase
                {...field}
                autoComplete="off"
                aria-label={name}
                id={name}
                name={name}
                label={label}
                placeholder={placeholder}
                fullWidth={fullWidth}
                onChange={(e) => {
                  let maskedValue = cpfMask(e.target.value)
                  field.onChange(maskedValue)
                  validateDocument(methods, maskedValue, name)
                }}
                onCut={onCut}
                onCopy={onCopy}
                onPaste={onPaste}
                helperText={helperText}
                inputProps={inputProps} />
          )} />
        {required && errors && errors[name] && (
          <GVFeedback danger>{errors[name].message}</GVFeedback>
        )}
      </FormControl>
    );
  } else {
    return (
      <FormControl error={Boolean(errors && errors[name])} fullWidth={fullWidth} variant="standard">
        <GVBRInputLabel component="legend" shrink htmlFor={name}>
          {StartIcon && <StartIcon style={{ fontSize: 20, marginRight: 5 }} />}
          {label}
        </GVBRInputLabel>
        <Controller
          name={name}
          control={control}
          rules={ required ? { required: errorMessage ? errorMessage : 'O campo é obrigatório' } : {}}
          defaultValue=""
          render={({ field }) => (
              <GVBRInputBase
                {...field}
                autoComplete="off"
                aria-label={name}
                id={name}
                name={name}
                label={label}
                placeholder={placeholder}
                fullWidth={fullWidth}
                onChange={(e) => {
                  field.onChange(e)
                  if (e.target.value.length > 0)
                    clearErrors(name)
                }}
                onCut={onCut}
                onCopy={onCopy}
                onPaste={onPaste}
                helperText={helperText}
                inputProps={inputProps} />
          )} />
        {required && errors && errors[name] && (
          <GVFeedback danger>{errors[name].message}</GVFeedback>
        )}
      </FormControl>
    );
  }
});

GVTextField.propTypes = {
  autoComplete: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  inputProps: PropTypes.object,
  StartIcon: PropTypes.elementType,
  type: PropTypes.string,
  icon: PropTypes.element
};

export default GVTextField;