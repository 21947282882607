import React from 'react';
import PropTypes from 'prop-types';
import styled from '@mui/system/styled';
import Alert, { alertClasses } from '@mui/material/Alert';
import AlertTitle, { alertTitleClasses } from '@mui/material/AlertTitle';
import CancelIcon from '@mui/icons-material/Cancel';import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import '../../../App.css'

export const getIcon = (danger, success, info, warning, warning2) => {
  if (danger) {
    return <CancelIcon fontSize="inherit" />;
  } else if (success) {
    return <CheckCircleIcon fontSize="inherit" />;
  } else if (info) {
    return <InfoIcon fontSize="inherit" />;
  } else if (warning || warning2) {
    return <WarningIcon fontSize="inherit" />;
  } else {
    return null;
  }
};

export const StyledGVAlertTitle = styled(AlertTitle)(({ theme }) => ({
  padding: '0',
  margin: '0',
  fontSize: '14px'
}))

const StyledGVMessage = styled(Alert)(({ theme, severity }) => {
  let backgroundColor, color, borderColor, iconColor;

  if (severity == 'error') {
    backgroundColor = 'var(--danger-alternative)';
    color = 'var(--pure-100)';
    borderColor = 'var(--danger-alternative)';
    iconColor = 'var(--danger)';
  } else if (severity == 'success') {
    backgroundColor = 'var(--success-alternative)';
    color = 'var(--pure-100)';
    borderColor = 'var(--success-alternative)';
    iconColor = 'var(--success)';
  } else if (severity == 'info') {
    backgroundColor = 'var(--info-alternative)';
    color = 'var(--pure-100)';
    borderColor = 'var(--info-alternative)';
    iconColor = 'var(--info)';
  } else if (severity == 'warning') {
    backgroundColor = 'var(--warning-alternative)';
    color = 'var(--pure-100)';
    borderColor = 'var(--warning-alternative)';
    iconColor = 'var(--pure-100)';
  } else if (severity == 'warning2') {
    backgroundColor = '#FFF5C2';
    color = '#333333';
    borderColor = '#FFF5C2';
    iconColor = '#333333';
  } else {
    backgroundColor = theme.palette.background.default;
    color = theme.palette.text.primary;
    borderColor = theme.palette.grey[500];
    iconColor = theme.palette.text.primary;
  }

  return {
    backgroundColor: backgroundColor,
    color: color,
    padding: 'var(--spacing-scale-2x) var(--spacing-scale-base) var(--spacing-scale-2x) var(--spacing-scale-base)',
    marginTop: 4,
    borderRadius: 0,
    fontSize: 'var(--font-size-scale-up-01)',
    fontStyle: 'italic',
    fontWeight: 'var(--font-weight-medium)',
    [`& .${alertClasses.icon}`]: {
      padding: 0,
      color: iconColor,
      fill: iconColor
    },
    [`& .${alertClasses.message}`]: {
      padding: 0,
      color: color
    },
  };
});

const GVMessage = ({ danger, success, info, warning, warning2, children, style }) => {
  const icon = getIcon(danger, success, info, warning, warning2);

  return (
    <StyledGVMessage icon={icon} style={style} severity={danger ? 'error' : (success ? 'success' : (info ? 'info' : (warning ? 'warning' : 'info')))}>
      <StyledGVAlertTitle>{children}</StyledGVAlertTitle>
    </StyledGVMessage>
  );
};

GVMessage.propTypes = {
  danger: PropTypes.bool,
  success: PropTypes.bool,
  info: PropTypes.bool,
  warning: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default GVMessage;