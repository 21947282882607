import { Mexx2DOConsole } from "./mexxTalkConsole"

export const isInvalidFileType = (type) => {
  Mexx2DOConsole.log("=== isInvalidFileType ===")
  Mexx2DOConsole.log(type)
  if (type === 'image/bmp' || type === 'image/gif' || type === 'image/jpeg' || type === 'image/png' || type === 'image/tiff' || type === 'application/msword' || type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || type === 'application/vnd.oasis.opendocument.presentation' || type === 'application/vnd.oasis.opendocument.spreadsheet' || type === 'application/vnd.oasis.opendocument.text' || type === 'application/pdf' || type === 'application/vnd.ms-powerpoint' || type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || type === 'application/rtf')
    return false
  else
    return true

  /*switch (type) {
    case 'image/bmp': // BMP
    case 'image/gif':  // GIf
    case 'image/jpeg': // JPG / JPEG
    case 'image/png': // PNG
    case 'image/tiff': // TIFF
    case 'application/msword': // DOC 
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': // DOCX
    case 'application/vnd.oasis.opendocument.presentation': // ODP
    case 'application/vnd.oasis.opendocument.spreadsheet': // ODS
    case 'application/vnd.oasis.opendocument.text': // ODT
    case 'application/pdf': // PDF
    case 'application/vnd.ms-powerpoint': // PPT
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': // PPTX
    case 'application/rtf': // RTF
      return true;
    default:
      return false;
  }*/
}

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const base64ToFile = (base64String, fileName) => {
  Mexx2DOConsole.log("=== base64ToFile ===")
  const arr = base64String.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  Mexx2DOConsole.log(arr)
  Mexx2DOConsole.log(mime)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  let file = new File([u8arr], `${fileName}.jpg`, { type: mime })
  
  Mexx2DOConsole.log(file)
  return file
}

export const filesToJSON = files => {
  Mexx2DOConsole.log("=== filesToJSON - files ===")
  Mexx2DOConsole.log(files)
  return Promise.all(Array.from(files).map(file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({
          name: file.name,
          type: file.type && file.type.length > 0 ? file.type : "image/jpeg",
          size: file.size,
          content: reader.result.split(',')[1] // extrai a parte base64
        });
      };
      reader.onerror = error => reject(error);
      reader.readAsDataURL(file); // lê o conteúdo do arquivo como base64
    });
  }));
}