import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import { thunk } from 'redux-thunk';

const persistConfig = {
    key: "root",
    storage,
    whitelist: ['calledServiceSettingsStep', 'userSession', 'users', 'faceRecognitionStep', 'forms', 'alterEmailStepForMine', 'documentRecognitionStep', 'stepper', 'createdCalled', 'triggers', 'attachmentForMinorStep']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          immutableCheck: false,
          serializableCheck: false,
        })
});

const persistor = persistStore(store);

export { store, persistor };
