import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import './styles.css'
import { styled } from '@mui/styles';
import { useMediaQuery } from '@mui/material';
import theme from '../../../styles/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons'
import { ThemeContext } from '../../../context/theme-context';

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme, ismobile }) => ({
  display: 'inline-grid',
  fontSize: 'var(--font-size-scale-down-01)',
  fontWeight: 'var(--font-weight-medium)',
  minHeight: 'var(--spacing-scale-3x)',
  position: 'relative',
  [`& .${breadcrumbsClasses.li}`]: {
    alignItems: 'center',
    display: 'flex',
    height: ismobile ? 'var(--spacing-scale-2x)' : 'var(--spacing-scale-5x)',
    [`& .MuiSvgIcon-root`]: {
      color: 'var(--interactive)',
      fontSize: '16px'
    }
  },
  [`& .${breadcrumbsClasses.separator}`]: {
    paddingBottom: '5px'
  },
  [`& .${breadcrumbsClasses.li}:last-child span`]: {
    fontWeight: 'var(--font-weight-medium)',
    margin: '0 var(--spacing-scale-2x) 0 var(--spacing-scale-base)',
    whiteSpace: 'nowrap'
  }
}))

export const BreadcrumbLink = styled(Link)(({ theme }) => ({
  overflow: 'hidden',
  textDecoration: 'none!important',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  color: 'var(--interactive)!important',
  cursor: 'pointer',
  padding: '0',
  fontSize: '11.662px!important',
  '&:focus-visible': {
    outlineColor: '#C2850C',
    outlineOffset: '4px',
    outlineStyle: 'dashed',
    outlineWidth: '4px'
  }
}))

const BreadcrumbTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'var(--font-weight-medium)',
  margin: '0 var(--spacing-scale-2x) 0 var(--spacing-scale-base)',
  whiteSpace: 'nowrap',
  fontSize: '11.662px!important'
}))

const BreadcrumbLinkContainer = styled('div')(({ theme }) => ({
  padding: '1px',
  borderRadius: '3px',
  '&:hover': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  }
}))

export default function IconBreadcrumbs(props) {
  const ismobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { theme: themeColor } = React.useContext(ThemeContext);

  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openURL = (url) => {
    window.open(url, '_self');
  }

  return (
    <div className='br-breadcumb' role="presentation">
      {ismobile ? (<StyledBreadcrumbs ismobile={`${ismobile}`} aria-label="breadcrumb" separator="›" >
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={handleMenuClick} >
          <FontAwesomeIcon icon={faFolderPlus}  fontSize="inherit" style={ themeColor === 'light' ? { color: 'var(--interactive)', width: '13.33px' } : { color: 'var(--interactive-dark)', width: '13.33px' }} />
        </IconButton>
        <BreadcrumbTypography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          {props.actualPage}
        </BreadcrumbTypography>
      </StyledBreadcrumbs>
        ) : (
      <StyledBreadcrumbs ismobile={`${ismobile}`} aria-label="breadcrumb" separator="›" >
        <BreadcrumbLinkContainer>
          <BreadcrumbLink
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href="https://www.gov.br/governodigital/pt-br"
            aria-label="Voltar ao site gov.br"
            target='blank'
          >
            <HomeIcon fontSize="inherit" />
          </BreadcrumbLink>
        </BreadcrumbLinkContainer>
        <BreadcrumbLinkContainer>
          <BreadcrumbLink
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href="https://www.gov.br/governodigital/pt-br/atendimento-gov.br"
            target='blank'
          >
            Atendimento gov.br
          </BreadcrumbLink>
        </BreadcrumbLinkContainer>
        <BreadcrumbLinkContainer>
          <BreadcrumbLink
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href="https://www.gov.br/governodigital/pt-br/atendimento-gov.br/duvidas-na-conta-gov.br"
            target='blank'
          >
            Dúvidas na gonta gov.br
          </BreadcrumbLink>
        </BreadcrumbLinkContainer>
        <BreadcrumbLinkContainer>
          <BreadcrumbLink
            sx={{ display: 'flex', alignItems: 'center' }}
            color="inherit"
            href={props.previousPage.link}
            target='blank'
          >
            {props.previousPage.text}
          </BreadcrumbLink>
        </BreadcrumbLinkContainer>
        <BreadcrumbTypography
          sx={{ display: 'flex', alignItems: 'center' }}
          color="text.primary"
        >
          {props.actualPage}
        </BreadcrumbTypography>
      </StyledBreadcrumbs>
      )}
      <Menu
      id="long-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      PaperProps={{
        style: {
          maxHeight: 48 * 4.5,
        },
      }}
    >
      <MenuItem onClick={() => openURL("https://www.gov.br/governodigital/pt-br")}>
        Home
      </MenuItem>
      <MenuItem onClick={() => openURL("https://www.gov.br/governodigital/pt-br/atendimento-gov.br")}>
        Atendimento gov.br
      </MenuItem>
      <MenuItem onClick={() => openURL("https://www.gov.br/governodigital/pt-br/atendimento-gov.br/duvidas-na-conta-gov.br")}>
        Dúvidas na conta gov.br
      </MenuItem>
      <MenuItem onClick={() => openURL(props.previousPage.link)}>
        {props.previousPage.text}
      </MenuItem>
    </Menu>
    </div>
  );
}