import { validateCpf } from "../../../../../utils/cpfUtils"
import { isEmail } from "../../../../../utils/mask/emailTestMask"
import StringUtils from "../../../../../utils/stringUtils"
import { useFormContext } from 'react-hook-form'

export const validateDocument = (methods, document, name) => {
  if (StringUtils.isNullOrEmpty(document))
    methods.setError(name, { type: 'manual', message: 'Preencha o CPF para avançar' })
  else if (document.length !== 14 || !validateCpf(document))
    methods.setError(name, { type: 'manual', message: 'CPF inválido' })
  else {
    methods.clearErrors(name)
  }
}

export const validateEmail = (methods, name, email) => {
  const formValues = methods.watch()
  if (name === 'confirmEmail') {
    if (StringUtils.isNullOrEmpty(email))
      methods.setError(name, { type: 'manual', message: 'Os e-mails digitados são diferentes' })
    else if (formValues.email !== formValues.confirmEmail)
      methods.setError(name, { type: 'manual', message: 'Os e-mails digitados são diferentes' })
    else
      methods.clearErrors(name)
  } else {
    if (StringUtils.isNullOrEmpty(email))
      methods.setError(name, { type: 'manual', message: 'Confirme o e-mail para avançar' })
    else if (!isEmail(email))
      methods.setError(name, { type: 'manual', message: 'Preencha um e-mail válido para avançar' })
    else if (formValues.email === formValues.confirmEmail)
      methods.clearErrors('confirmEmail')
    else
      methods.clearErrors(name)
  }
}