import Api from './api';

export const getConfigurationsByRoute = async route => { 
  let data = [];
  await Api.get(`/organization/calledservicesettings/route/${route}`)
  .then(result => {
    data = result.data
  })
  .catch(err => {
    data = err;
  })
  
  return data;
}

export const getConfigurationsById = async id => { 
  let data = [];
  await Api.get(`/organization/calledservicesettings/${id}`)
  .then(result => {
    data = result.data
  })
  .catch(err => {
    data = err;
  })
  
  return data;
}