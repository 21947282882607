import React from 'react'
import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import DocumentoCertidao from '../../../../../../components/images/documento-certidao';
import DocumentoDecisao from '../../../../../../components/images/documento-decisao';
import DocumentoCPFFrente from '../../../../../../components/images/documento-cpf-frente';
import DocumentoCPFVerso from '../../../../../../components/images/documento-cpf-verso';
import DocumentoRGFrente from '../../../../../../components/images/documento-rg-frente';
import DocumentoRGVerso from '../../../../../../components/images/documento-rg-verso';
import { useSelector } from 'react-redux';

const DocumentTypeInfo = React.memo(function DocumentTypeInfo({ inputName }) {
  const methods = useFormContext();
  const formValues = methods.watch()

  const attachmentForMinordocumentNifStep = useSelector(state => state.attachmentForMinorStep.documentStep);

  if (formValues[inputName] && formValues[inputName].name === 'Carteira de Identidade da criança ou adolescente + comprovante de inscrição no CPF') {
    if (attachmentForMinordocumentNifStep === 1) {
      return (<Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DocumentoRGFrente />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <DocumentoRGVerso />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" fontWeight="italic" >Primeiramente vamos realizar a leitura da carteira de identidade.</Typography>
        </Grid>
      </Grid>)
    } else {
      return (<Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DocumentoCPFFrente />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <DocumentoCPFVerso /> 
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" fontWeight="italic" >Agora vamos realizar a leitura no comprovante de inscrição no CPF.</Typography>
        </Grid>
      </Grid>)
    }
  } else if (formValues[inputName] && formValues[inputName].name === 'Certição de nascimento da criança ou adolescente') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <DocumentoCertidao />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Typography variant="body1" component="span" >A certidão de nascimento da criança ou do adolescente precisa ser original e autenticada em cartório</Typography>
      </Grid>
    </Grid>)
  } else if (formValues[inputName] && formValues[inputName].name === 'Termo judicial de guarda, tutoria ou curadoria') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <DocumentoDecisao />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Typography variant="body1" component="span" >O termo judicial de guarda, tutoria ou curadoria deve apresentar o nome e o CPF tanto da criança ou adolescente quanto do responsável legal. Além disso, o documento também deverá possuir uma forma de comprovação oficial de autenticidade pelo órgão emissor.5471</Typography>
      </Grid>
    </Grid>)
  } else {
    return (<React.Fragment></React.Fragment>)
  }
})

export default DocumentTypeInfo;