import React from 'react'
import { useFormContext } from 'react-hook-form'
import GVRadioButton from '../../../../components/gov-br/radio'
import GVTextField from '../../../../components/gov-br/textfield';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MailIcon from '@mui/icons-material/Mail';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { CircularProgress, Grid, InputAdornment } from '@mui/material';
import GvDialog from '../../../../components/gov-br/dialog';
import ConfirmEmailDialog from './components/email-confirm-dialog';
import * as componentActions from '../../../../redux/actions/alter-email-step-for-mine'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import GVAutocomplete from '../../../../components/gov-br/autocomplete';
import CompletedFormReadData from '../completed-form-read-data';
import GVMessage from '../../../../components/gov-br/message';
import { Overlay } from '../../../../styles/global';
import CustomizedSnackbars from '../../../../components/material-snackbars';
import GVFeedback from '../../../../components/feedback';

const StepForMine = React.memo(function StepForMine(props) {
  const methods = useFormContext()
  const formValues = methods.watch()

  const completed = props.stepper.completed
  const isCompleted = completed?.[1] === true;

  const [loading, setLoading] = React.useState(false)
  const [openNotification, setOpenNotification] = React.useState(false)
  const [notificationVariant, setNotificationVariant] = React.useState("success")
  const [notificationMessage, setNotificationMessage] = React.useState("")

  const notEventChange = (e) => {
    e.preventDefault()
  }

  const showSnackbar = (type, message) => {
    setLoading(false)
    setOpenNotification(true)
    setNotificationMessage(message)
    setNotificationVariant(type)
  }

  const showLoading = (isShow) => {
    setLoading(isShow)
  }

  const closeNotification = _ => setOpenNotification(false)

  if (isCompleted) {
    return (<Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVMessage success>Esta etapa já foi concluída. Caso deseje alterar os dados, volte ao início</GVMessage>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <CompletedFormReadData
          label="Nome do titular da conta"
          StartIcon={PersonIcon}
          data={`${formValues["name"]}`} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <CompletedFormReadData
          label="CPF do titular da conta"
          StartIcon={AssignmentIndIcon}
          data={`${formValues["document"]}`} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <CompletedFormReadData
          label="E-mail a ser cadastrado"
          StartIcon={MailIcon}
          data={`${formValues["email"]}`} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <CompletedFormReadData
          label="Confirmar E-mail"
          StartIcon={MarkEmailReadIcon}
          data={`${formValues["email"]}`} />
      </Grid>
    </Grid>)
  } else {
    return (<React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <GVTextField
            name="name"
            label="Nome do titular da conta"
            placeholder="Digite o nome completo"
            errorMessage="Preencha o nome para avançar"
            type="text"
            fullWidth
            required
            StartIcon={PersonIcon}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon style={{ fontSize: 20, marginRight: 5 }} />
                </InputAdornment>
              )
            }} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <GVTextField
            name="document"
            label="CPF do titular da conta"
            placeholder="Digite o número do CPF"
            errorMessage="Preencha o CPF para avançar"
            type="document"
            fullWidth
            required
            StartIcon={AssignmentIndIcon}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonIcon style={{ fontSize: 20, marginRight: 5 }} />
                </InputAdornment>
              )
            }} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <GVTextField
            name="email"
            label="E-mail a ser cadastrado"
            placeholder="Digite o e-mail"
            errorMessage="Confirme o e-mail para avançar"
            type="email"
            fullWidth
            required
            onBlur={(e) => {
              e.preventDefault()
              props.completeDialogAlterEmailStepForMineConfirmEmail(false)
            }}
            onCut={notEventChange}
            onCopy={notEventChange}
            onPaste={notEventChange}
            StartIcon={MailIcon}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailIcon style={{ fontSize: 20, marginRight: 5 }} />
                </InputAdornment>
              )
            }} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <GVTextField
            name="confirmEmail"
            label="Confirmar E-mail"
            placeholder="Digite o e-mail"
            errorMessage="Os e-mails digitados são diferentes"
            type="email"
            autoComplete="new-password"
            fullWidth
            required
            onCut={notEventChange}
            onCopy={notEventChange}
            onPaste={notEventChange}
            StartIcon={MarkEmailReadIcon}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MarkEmailReadIcon style={{ fontSize: 20, marginRight: 5 }} />
                </InputAdornment>
              )
            }} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <GVFeedback warning2>A conta gov.br possui um limite para o uso do mesmo e-mail em contas diferentes. Use o <strong>e-mail do titular</strong> e tenha certeza que tem acesso a ele.</GVFeedback>
        </Grid>
        <ConfirmEmailDialog
          showSnackbar={showSnackbar}
          showLoading={showLoading}
          closeNotification={closeNotification} />
      </Grid>
      {loading && (
        <Overlay>
          <CircularProgress color="secondary" />
        </Overlay>
      )}
      <CustomizedSnackbars
        variant={notificationVariant}
        message={notificationMessage}
        isOpen={openNotification}
        toClose={closeNotification} />
    </React.Fragment>)
  }
})

const mapStateToProps = state => ({
  stepper: state.stepper
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StepForMine);