import React from 'react'
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness'
import { View, Heading, Alert, Card, Text } from '@aws-amplify/ui-react'
import PropTypes from "prop-types"
import FaceRecognitionNear from '../images/face_recognition_near'
import FaceRecognitionFar from '../images/face_recognition_far'
import './styles.css'

const dictionary = {
	// use default strings for english
	en: null,
	ptbr: {
	  instructionsHeaderHeadingText: 'Verificação de vida',
	  instructionsHeaderBodyText:
		'Você passará por um processo de verificação facial para provar que é uma pessoa real.',
	  instructionListStepOneText:
		'Quando uma moldura oval aparecer, preencha ela com seu rosto durante 7 segundos.',
	  instructionListStepTwoText: 'Maximize o brilho da tela.',
	  instructionListStepThreeText:
		'Certifique-se de que seu rosto não esteja coberto por óculos de sol ou máscara.',
	  instructionListStepFourText:
		'Vá para uma área bem iluminada que não esteja exposta à luz solar direta.',
	  photosensitivyWarningHeadingText: 'Aviso de fotossensibilidade',
	  photosensitivyWarningBodyText:
		'Esta verificação mostra luzes coloridas. Tenha cuidado se você for fotossensível.',
	  instructionListHeadingText:
		'Siga as instruções para concluir a verificação:',
	  goodFitCaptionText: 'Bom encaixe',
	  tooFarCaptionText: 'Longe demais',

	  instructionsBeginCheckText: 'Iniciar',
	  photosensitivyWarningInfoText:
		'Uma pequena porcentagem de indivíduos pode sofrer ataques epiléticos quando expostos a luzes coloridas. Tenha cuidado se você ou alguém da sua família tiver uma condição epiléptica.',
	  goodFitAltText:
		"Ilustração do rosto de uma pessoa, encaixando-se perfeitamente dentro de um oval.",
	  tooFarAltText:
		"Ilustração do rosto de uma pessoa dentro de um oval; há uma lacuna entre o perímetro da face e os limites do oval.",
	  cameraMinSpecificationsHeadingText:
		'A câmera não atende às especificações mínimas',
	  cameraMinSpecificationsMessageText:
		'A câmera deve suportar pelo menos 320*240 de resolução e 15 quadros por segundo.',
	  cameraNotFoundHeadingText: 'Câmera não acessível.',
	  cameraNotFoundMessageText:
		'Verifique se a câmera está conectada e se as permissões da câmera estão ativadas nas configurações antes de tentar novamente.',
	  retryCameraPermissionsText: 'Tentar novamente',
	  cancelLivenessCheckText: 'Cancelar verificação de vivacidade',
	  recordingIndicatorText: 'gravando...',
	  hintMoveFaceFrontOfCameraText: 'Mova o rosto na frente da câmera',
	  hintTooManyFacesText: 'Certifique-se de que apenas 1(um) rosto esteja na frente da câmera',
	  hintFaceDetectedText: 'Rosto detectado',
	  hintCanNotIdentifyText: 'Mova o rosto na frente da câmera',
	  hintTooCloseText: 'Volte um pouco',
	  hintTooFarText: 'Aproxime-se',
	  hintHoldFacePositionCountdownText: 'Mantenha a posição do rosto durante a contagem regressiva',
	  hintConnectingText: 'Conectando...',
	  hintVerifyingText: 'Verificando...',
	  hintIlluminationTooBrightText: 'Mover para a área mais escura',
	  hintIlluminationTooDarkText: 'Move to brighter area',
	  hintIlluminationNormalText: 'Mover para uma área mais clara',
	  hintHoldFaceForFreshnessText: 'Segure e aguarde firme',
	},
}

function CustomFaceLivenessDetector(props) {

  return (<FaceLivenessDetector
    sessionId={props.sessionId}
    region="us-east-1"
    onAnalysisComplete={props.handleAnalysisComplete}
    onUserCancel={props.handleUserCancel}
    onError={props.handleError}
    disableStartScreen={true}
    disableInstructionScreen={true}
    displayText={dictionary['ptbr']}
    components={{
      Header: () => {
        return (<></>)
      },
      PhotosensitiveWarning: () => {
        return (<></>)
      },
      Instructions: () => {
        return (<></>)
      }
    }} />)
}

CustomFaceLivenessDetector.propTypes = {
  sessionId: PropTypes.string.isRequired,
  handleAnalysisComplete: PropTypes.func.isRequired,
  handleUserCancel: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
}

export default CustomFaceLivenessDetector