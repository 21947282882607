import React from 'react'
import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import DocumentoCNHFrente from '../../../../../../components/images/documento-cnh-frente';
import DocumentoCINFrente from '../../../../../../components/images/documento-cin-frente';
import DocumentoCINVerso from '../../../../../../components/images/documento-cin-verso';
import DocumentoCTPSFrente from '../../../../../../components/images/documento-ctps-frente';
import DocumentoCTPSVerso from '../../../../../../components/images/documento-ctps-verso';
import DocumentoCFFrente from '../../../../../../components/images/documento-cf-frente';
import DocumentoCFVerso from '../../../../../../components/images/documento-cf-verso';
import DocumentoIdentificacaoDigital from '../../../../../../components/images/documento-identificacao-digital';
import DocumentoCNHVerso from '../../../../../../components/images/documento-cnh-verso';
import DocumentoCRNMFrente from '../../../../../../components/images/documento-crnm-frente';
import DocumentoCRNMVerso from '../../../../../../components/images/documento-crnm-verso';
import DocumentoCIMFrente from '../../../../../../components/images/documento-cim-frente';
import DocumentoCIMVerso from '../../../../../../components/images/documento-cim-verso';
import DocumentoRGFrente from '../../../../../../components/images/documento-rg-frente';
import DocumentoRGVerso from '../../../../../../components/images/documento-rg-verso';
import { useSelector } from 'react-redux';
import DocumentoCPFFrente from '../../../../../../components/images/documento-cpf-frente';
import DocumentoCPFVerso from '../../../../../../components/images/documento-cpf-verso';

const DocumentTypeInfo = React.memo(function DocumentTypeInfo({ inputName }) {
  const methods = useFormContext();
  const formValues = methods.watch()

  const documentRecognitionStep = useSelector(state => state.documentRecognitionStep.documentNifStep);

  if (formValues[inputName] && formValues[inputName].name === 'Carteira de Identidade (RG) + Comprovante de Inscrição no CPF') {
    if (documentRecognitionStep === 1) {
      return (<Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DocumentoRGFrente />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <DocumentoRGVerso />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" fontWeight="italic" >Primeiramente vamos realizar a leitura da carteira de identidade.</Typography>
        </Grid>
      </Grid>)
    } else {
      return (<Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <DocumentoCPFFrente />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <DocumentoCPFVerso /> 
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" fontWeight="italic" >Agora vamos realizar a leitura no comprovante de inscrição no CPF.</Typography>
        </Grid>
      </Grid>)
    }
  } else if (formValues[inputName] && formValues[inputName].name === 'Carteira de Identidade Nacional (CIN)') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DocumentoCINFrente />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <DocumentoCINVerso />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
    </Grid>)
  } else if (formValues[inputName] && formValues[inputName].name === 'Carteira Nacional de Habilitação (CNH)') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DocumentoCNHFrente />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <DocumentoCNHVerso />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
    </Grid>)
  } else if (formValues[inputName] && formValues[inputName].name === 'Carteira de Trabalho e Previdência Social (CTPS)') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DocumentoCTPSFrente />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <DocumentoCTPSVerso />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Typography variant="body1" component="span" >A carteira de trabalho precisa apresentar a foto e o número do CPF</Typography>
      </Grid>
    </Grid>)
  } else if (formValues[inputName] && formValues[inputName].name === 'Carteira de identidade profissional ou carteira funcional') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DocumentoCFFrente />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <DocumentoCFVerso />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Typography variant="body1" component="span" >A Carteira de identidade profissional deverá ter sido emitida por órgaõs fiscalizadores de exercício de profissão regulamentada. Alguns exemplos são OAB, CRC, CRM, CRA, etc. A Carteira Funcional deverá ter sido emitida por órgao público e ser reconhecida por Lei Federal como documento de identidade válido em todo território nacional.</Typography>
      </Grid>
    </Grid>)
  } else if (formValues[inputName] && formValues[inputName].name === 'Carteira de identidade militar') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DocumentoCIMFrente />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <DocumentoCIMVerso />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
    </Grid>)
  } else if (formValues[inputName] && formValues[inputName].name === 'Carteira do Registro nacional Imigratório (CRNM)') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <DocumentoCRNMFrente />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <DocumentoCRNMVerso />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
    </Grid>)
  } else if (formValues[inputName] && formValues[inputName].name === 'Documento digital de identificação') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <DocumentoIdentificacaoDigital />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Typography variant="body1" component="span" >O documento digital utilizado deverá ser reconhecido por Lei Federal como documento de identidade válido em todo território nacional. Alguns exemplos são a CIN e a CNH.</Typography>
      </Grid>
    </Grid>)
  } else {
    return (<React.Fragment></React.Fragment>)
  }
})

export default DocumentTypeInfo;