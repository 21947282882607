import ApiMexx2Face from './apiMexx2Face';

export const createFaceLivenessSession = async _ => { 
  let data = []
  await ApiMexx2Face.get(`/faceliveness/create-session`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const getFaceLivenessSessionResult = async sessionId => { 
  let data = []
  await ApiMexx2Face.get(`/faceliveness/get-session-result/${sessionId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const cancelFaceLivenessSession = async sessionId => { 
  let data = []
  await ApiMexx2Face.get(`/faceliveness/cancel-session/${sessionId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const restartFaceLivenessSession = async sessionId => { 
  let data = []
  await ApiMexx2Face.get(`/faceliveness/restart-session/${sessionId}`)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}