import DeleteIcon from '@mui/icons-material/Delete'
import { Grid, Typography } from '@mui/material'
import IconButton, { IconButtonClasses, iconButtonClasses } from '@mui/material/IconButton'
import { styled } from '@mui/styles'
import React, { useContext } from 'react'
import { useDropzone } from 'react-dropzone'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { formatBytes } from '../../../utils/fileUtils'
import GVTooltip from '../../tooltip'
import '../../../App.css'
import GVIconButton from '../../icon-button'
import './styles.css'
import GVFeedback from '../../feedback'
import { ThemeContext } from '../../../context/theme-context'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Controller, useFormContext } from 'react-hook-form'
import { Mexx2DOConsole } from '../../../utils/mexxTalkConsole'
import theme from '../../../styles/theme'

const Dropzone = styled('div')(({ theme }) => {
  return ({
  '--button-radius': '100em',
  '--button-xsmall': '24px',
  '--button-small': '32px',
  '--button-medium': '40px',
  '--button-large': '48px',
  '--button-size': 'var(--button-medium)',
  backgroundColor: 'transparent',
  border: '0',
  borderRadius: 'var(--button-radius)',
  color: theme.palette.mode === 'dark' ? 'var(--interactive-dark)' : 'var(--interactive)',
  cursor: 'pointer',
  display: 'inline-flex',
  fontSize: 'var(--font-size-scale-up-01)',
  fontWeight: 'var(--font-weight-semi-bold)',
  height: 'var(--button-size)',
  justifyContent: 'center',
  overflow: 'hidden',
  padding: '0 var(--spacing-scale-3x)',
  position: 'relative',
  textAlign: 'center',
  verticalAlign: 'middle',
  whiteSpace: 'nowrap',
  width: 'auto',
  border: theme.palette.mode === 'dark' ? 'var(--surface-width-sm) dashed var(--interactive-dark)' : 'var(--surface-width-sm) dashed var(--interactive)',
  borderRadius: 'var(--surface-rounder-sm)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  fontSize: 'var(--font-size-scale-base)',
  fontStyle: 'italic',
  fontWeight: 'var(--font-weight-regular)',
  marginTop: 'var(--spacing-scale-half)',
  maxWidth: '550px',
  minHeight: 'var(--button-size)',
  paddingLeft: 'var(--spacing-scale-2x)',
  paddingRight: 'var(--spacing-scale-2x)',
  textAlign: 'left',
  width: '100%',
  '@media (max-width:575px)': {
    marginTop: 'var(--spacing-scale-baseh)',
    marginRight: 'var(--spacing-scale-2x)',
    borderColor: 'var(--danger)',
    maxWidth: '100%',
  },
  '&:hover': {
    backgroundImage: theme.palette.mode === 'dark' ? 'linear-gradient(rgba(var(--interactive-dark-rgb), var(--hover)), rgba(var(--interactive-dark-rgb), var(--hover)))' : 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
  '& svg + p': {
    marginLeft: 'var(--spacing-scale-base)'
  },
  '&:focus-visible': {
    outlineColor: '#C2850C',
    outlineOffset: '4px',
    outlineStyle: 'dashed',
    outlineWidth: '4px'
  }
})})

const ExtendedDropzone = (props) => {

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const dropzoneText = isMobile ? 'Selecione o arquivo aqui' : 'Arraste e solte ou selecione o arquivo aqui'

  const { getRootProps, getInputProps } = useDropzone(props);

  return (
    <div>
      <Dropzone style={(props.itemError && props.itemError[`files-${props.itemId}`]) ? { borderColor: 'var(--danger)', display: 'flex' } : { display: 'flex' }} {...getRootProps()}>
        <input {...getInputProps()} />
        <FileUploadIcon />
        <Typography variant="body1" className={props.classStyle}>{dropzoneText}</Typography>
      </Dropzone>
    </div>
  );
};

const GVFileUpload = React.memo(function GVFileUpload({ item, classes, classStyle }) {
  const { theme } = useContext(ThemeContext)
  const themeU = useTheme();
  const isXsOrSm = useMediaQuery(themeU.breakpoints.between('xs', 'sm'));
  const methods = useFormContext();
  const { control, clearErrors, formState: { errors }, setError, setValue, watch } = methods
  const formValues = watch()

  const attachmentList = item.calledservicesettingsfileuploadattachments && item.calledservicesettingsfileuploadattachments.length > 0 ? item.calledservicesettingsfileuploadattachments : []

  const setFileUploadState = (stateName, acceptedFiles) => {
    setValue(stateName, acceptedFiles)
    if (acceptedFiles && acceptedFiles.length > 0 && errors[stateName]) {
      methods.clearErrors(stateName)
    }
  }

  const validateFiles = (stateName, acceptedFiles, fileLimit) => {
    if (acceptedFiles && acceptedFiles.length > fileLimit) {
      setError(stateName, { type: 'manual', message: `Você precisa inserir no máximo ${fileLimit} anexos` })
    } else {
      clearErrors(stateName)
      setValue(stateName, acceptedFiles)
    }
  }

  const removeFilesUploadItems = (stateName, file) => {
    let fileListCount = formValues[stateName] && formValues[stateName].length > 0 ? formValues[stateName].length : 0
    if (file && file.name) {
      let prevFileList = formValues[stateName] && formValues[stateName].length > 0 ? formValues[stateName].filter(function(element) {
        return element.name !== file.name;
      }) : []
      fileListCount = prevFileList.length
      setValue(stateName, prevFileList)
    } else
      setValue(stateName, [])

    if (fileListCount > 0 && errors[stateName]) {
      clearErrors(stateName)
    }
  }

  return (<Grid key={item.id} item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: 24 }}>
    <Grid container
      rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
      columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }} >
      { (item.description && item.description.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12}>
        <div dangerouslySetInnerHTML={ {__html: item.description } }></div>
      </Grid>}
      {attachmentList.map(attachmentItem => {
        return (
          <Grid item xs={6} sm={6} md={6} lg={6}>
            <img src={ `${attachmentItem.attachment.url}` } alt={ `${attachmentItem.attachment.description}` } style={{ maxWidth: '100%' }} />
          </Grid>
        )
      })}
      {(item.label && item.label.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12}>
        <label for={`${item.label}-input`} style={{ fontWeight: 'bold' }}>
          {item.label}* {item.filelimit > 1 && `(Selecione no máximo ${item.filelimit} anexos)`}:
        </label>
      </Grid>}
      <Grid item xs={12} sm={12} md={10} lg={8} style={{ paddingTop: 0 }}>
        <Grid container
         rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
         columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }} >
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <div>
            {item.filelimit > 1 ? 
            <Controller
              name={`files-${item.id}`}
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <ExtendedDropzone
                  {...field}
                  id={`${item.label}-input`}
                  name={`files-${item.id}`}
                  itemId={item.id}
                  itemError={errors}
                  classStyle={classStyle}
                  maxFiles={item.filelimit}
                  onDrop={acceptedFiles => {
                    validateFiles(`files-${item.id}`, acceptedFiles, item.filelimit)
                  }} />
              )} />
            :
            <Controller
              name={`files-${item.id}`}
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <ExtendedDropzone
                  {...field}
                  id={`${item.label}-input`}
                  name={`files-${item.id}`}
                  itemId={item.id}
                  itemError={errors}
                  classStyle={classStyle}
                  multiple={false}
                  onDrop={acceptedFiles => {
                    setFileUploadState(`files-${item.id}`, acceptedFiles)
                  }}
                  />
              )} />}
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={4} style={isXsOrSm ? { padding: 0 } : {}} />
      { errors[`files-${item.id}`] && <Grid item xs={12} sm={12} md={10} lg={8} style={{ paddingTop: 0 }}>
        <GVFeedback style={{ maxWidth: 550 }} danger>{errors[`files-${item.id}`].message}</GVFeedback>
      </Grid>}
      { errors[`files-${item.id}`] && <Grid item xs={12} sm={12} md={2} lg={4} />}
      {formValues[`files-${item.id}`] && formValues[`files-${item.id}`].length > 0 && <Grid item xs={12} sm={12} md={10} lg={8}
        className='upload-file-list'>
        <Grid container
          rowSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
          columnSpacing={{ xs: '16px', sm: '24px', md: '24px', lg: '24px', xl: '40px' }}
          className='file-upload-item' >
          {formValues[`files-${item.id}`].map((file, fileIndex) => {
          return (<Grid key={item.id} item xs={12} sm={12} md={12} lg={12} className='file-upload-item-row'>
            <Grid container
              rowSpacing={{ xs: '6px', sm: '12px', md: '24px', lg: '24px', xl: '40px' }}
              columnSpacing={{ xs: '6px', sm: '12px', md: '24px', lg: '24px', xl: '40px' }} >
              <Grid item xs={7} sm={7} md={8} lg={8}
                style={{
                  border: '0',
                  color: theme === 'light' ? '#1351b4' : '#FFF',
                  fontWeight: '400',
                  textAlign: 'left',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  maxWidth: '50%',
                }} className='file-upload-item-column'>
                <GVTooltip title={file.name}>
                  <div style={{
                    color: theme === 'light' ? '#1351b4' : '#FFF',
                    fontWeight: '400',
                    textAlign: 'left',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}>{file.name}</div>
                </GVTooltip>
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}
                style={{
                  border: '0',
                  color: theme === 'light' ? 'var(--color)' : 'var(--pure-0)',
                  fontWeight: '400',
                  textAlign: 'left',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }} className='file-upload-item-column'>
                <span>{formatBytes(file.size)}</span>
              </Grid>
              <Grid item xs={2} sm={2} md={1} lg={1}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
                className='file-upload-item-column'>
                <GVIconButton
                  aria-label="delete-file"
                  onClick={() => { removeFilesUploadItems(`files-${item.id}`, file) }} >
                  <DeleteIcon style={{ color: 'var(--interactive)' }} className={classes.deleteItem} />
                </GVIconButton>
              </Grid>
            </Grid>
          </Grid>)
          })}
        </Grid>
      </Grid>}
    </Grid>
  </Grid>)
})

export default GVFileUpload