import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { useMediaQuery } from '@mui/material';
import theme from '../../styles/theme'
import '../../styles/fonts/font-family.css'
import GVStepper from '../../components/gov-br/stepper'
import Header from '../../components/gov-br/header';
import Footer from '../../components/gov-br/footer'
import IconBreadcrumbs, { BreadcrumbLink } from '../../components/gov-br/breadcrumbs';
import CookiesBanner from '../../components/gov-br/cookiebar';
import { Box, Card, CardContent, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha";
import GVButton from '../../components/gov-br/button';
import { FormProvider, useForm } from 'react-hook-form';
import StepGeneralInfo from './components/step-general-info';
import { isNumberAndGreaterThanZero, randomIntFromInterval } from '../../utils/numberUtils';
import StringUtils from '../../utils/stringUtils';
import StepForMine from './components/step-for-mine';
import { isEmail } from '../../utils/mask/emailTestMask';
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../redux/actions/alter-email-step-for-mine'
import * as documentRecognitionStepComponentActions from '../../redux/actions/document-recognition-step'
import * as faceRecognitionStepComponentActions from '../../redux/actions/face-recognition-step'
import * as attachmentForMinorStepComponentActions from '../../redux/actions/attachment-for-minor-step'
import * as stepperComponentActions from '../../redux/actions/stepper'
import * as createdCalledActions from '../../redux/actions/created-called'
import * as stepComponentActions from '../../redux/actions/called-service-settings-step'
import * as triggersComponentActions from '../../redux/actions/triggers';
import StepFaceRecognition from './components/step-face-recognition';
import StepDocumentRecognition from './components/step-document-recognition';
import StepSend from './components/step-send';
import StepForMinor from './components/step-for-minor';
import StepCompleted from './components/step-completed';
import StepForOther from './components/step-for-other';
import StepAttachmentMinor from './components/step-attachment-minor';
import StepAttachmentOther from './components/step-attachment-other';
import DocumentExistsValidationDialog from '../../components/gov-br/document-exists-validation-dialog';
import EmailExistsValidationDialog from '../../components/gov-br/email-exists-validation-dialog';
import DocumentPepValidationDialog from '../../components/gov-br/document-pep-validation';
import DuvidaIlustracaoImage from '../../components/images/duvida-ilustracao';
import GvDialog from '../../components/gov-br/dialog';
import DocumentBlacklistValidationDialog from '../../components/gov-br/document-blacklist-validation copy';
import { validateCpf } from '../../utils/cpfUtils';
import IconSun from '../../components/icons/icon-sun';
import IconNoGlasses from '../../components/icons/icon-no-glasses';
import IconSunBright from '../../components/icons/icon-sun-bright';
import IconFaceFrame from '../../components/icons/icon-face-frame';
import IconCaptureDocument from '../../components/icons/icon-face-capture-document';
import IconDocumentSwitch from '../../components/icons/icon-face-document-switch';
import LivenessFaceClass from '../../components/liveness-face-class';
import { Overlay } from '../../styles/global';
import CustomizedSnackbars from '../../components/material-snackbars';
import { getConfigurationsByRoute } from '../../services/organization.service';
import Api from '../../services/api'
import { createClientExternalCalled, validateExternalCalled } from '../../services/called.service'
import moment from 'moment';
import { base64ToFile, isInvalidFileType } from '../../utils/fileUtils';
import QRCode from 'react-qr-code';
import MultiStepCamera from '../../components/gov-br/multi-step-camera';
import DocumentRecognitionDialog from './components/step-document-recognition/components/document-recognition-dialog';
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole';
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TagIcon from '@mui/icons-material/Tag';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './styles.css'
import GVMyDataDialogContent from '../../components/gov-br/dialog/my-data-dialog-content';
import OpenedCalled from '../../components/error-content/opened-called';
import PEP from '../../components/error-content/pep';
import Blacklist from '../../components/error-content/blacklist';
import Validation from '../../components/error-content/validation';

const Disable2FactorVerification = (props) => {
  const { lang } = useParams()

  const currentURL = `${window.location.href.split(`/${lang}/`)[0]}/${lang}`

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.down('desktop'));
  const buttonCompletedRef = useRef(null)
  const livenessClassRef = useRef(null)

  const [interactionOccurred, setInteractionOccurred] = useState(false);
  const alterEmailStepForMineIsCompleted = useSelector(state => state.alterEmailStepForMine.isCompleted);
  const [documentInteractionOccurred, setDocumentInteractionOccurred] = useState(false);
  const documentRecognitionStepIsCompleted = useSelector(state => state.documentRecognitionStep.isCompleted);
  const attachmentForMinordocumentNifStep = useSelector(state => state.attachmentForMinorStep.documentStep);

  const methods = useForm();
  const formStateValues = methods.watch()
  const [startForm, setStartForm] = useState(false)

  const [emailExistsDialogOpen, setEmailExistsDialogOpen] = useState(false)
  const [emailExistsProtocol, setEmailExistsProtocol] = useState('0000000')
  const [emailExistsOpeningDate, setEmailExistsOpeningDate] = useState('00/00/0000 00:00:00')

  const [documentExistsDialogOpen, setDocumentExistsDialogOpen] = useState(false)
  const [documentExistsProtocol, setDocumentExistsProtocol] = useState('0000000')
  const [documentExistsOpeningDate, setDocumentExistsOpeningDate] = useState('00/00/0000 00:00:00')

  const [documentPepDialogOpen, setDocumentPepDialogOpen] = useState(false)
  const [documentPep, setDocumentPep] = useState('000.000.000-00')

  const [documentBlacklistDialogOpen, setDocumentBlacklistDialogOpen] = useState(false)
  const [documentBlacklist, setDocumentBlacklist] = useState('000.000.000-00')

  const [notOnTheCellphoneDialogOpen, setNotOnTheCellphoneDialogOpen] = useState(false)

  const [faceRecognitionDialogOpen, setFaceRecognitionDialogOpen] = useState(false)
  const [faceRecognitionDialogStep, setFaceRecognitionDialogStep] = useState(1)
  const [referenceimage, setReferenceimage] = useState("")
  const [urlreferenceimage, setUrlreferenceimage] = useState("")
  const faceRecognitionStepHasError = useSelector(state => state.faceRecognitionStep.hasError)
  const faceRecognitionStepHasAttemps = useSelector(state => state.faceRecognitionStep.attemps)

  const [documentRecognitionDialogOpen, setDocumentRecognitionDialogOpen] = useState(false)
  const [documentRecognitionDialogStep, setDocumentRecognitionDialogStep] = useState(1)

  const [apiError, setApiError] = useState(false)

  const [loading, setLoading] = useState(false)
  const [openNotification, setOpenNotification] = useState(false)
  const [notificationVariant, setNotificationVariant] = useState("success")
  const [notificationMessage, setNotificationMessage] = useState("")

  const [legalResponsible, setLegalResponsible] = useState(false)
  const [legalRepresentant, setLegalRepresentant] = useState(false)

  const [myDataDialogOpen, setMyDataDialogOpen] = useState(false)

  const stepsData = useSelector((state) => state.calledServiceSettingsStep.steps)
  const stepForMinor = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 3).sort((a, b) => a.order - b.order) : []
  const stepForOther = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 4).sort((a, b) => a.order - b.order) : []

  const allStepsCompleted = useSelector((state) => state.stepper.allStepsCompleted)
  const isAllStepsCompleted = allStepsCompleted?.[0] === true;

  const [documentExistsFormValidationError, setDocumentExistsFormValidationError] = useState(false)
  const [pepFormValidationError, setPepExistsFormValidationError] = useState(false)
  const [blacklistFormValidationError, setBlacklistExistsFormValidationError] = useState(false)
  
  const [dynamicFormValidationError, setDynamicFormValidationError] = useState(false)
  const [dynamicValidationErrorDescription, setDynamicFormValidationErrorDescription] = useState("")

  const faceRecognitionStepAttempsBlocked = faceRecognitionStepHasAttemps != null && faceRecognitionStepHasAttemps >= 10
  const formValidationError = faceRecognitionStepAttempsBlocked || documentExistsFormValidationError || pepFormValidationError || blacklistFormValidationError || dynamicFormValidationError
  const formValidationChatError = faceRecognitionStepAttempsBlocked || blacklistFormValidationError || pepFormValidationError || dynamicFormValidationError

  const onSubmit = () => {
    alert('Formulário enviado com sucesso!');
  };

  const autocompleteOptions = [
    { label: 'Opção 1', value: 'option1' },
    { label: 'Opção 2', value: 'option2' },
  ];

  const appTheme = createTheme({
    palette: {
      mode: 'light',
    },
  });

  const onChangeCaptcha = (value) => {
    alert("Captcha value:", value);
  }

  const handleStart = (e) => {
    e.preventDefault()
    setStartForm(true)
  }

  const openNotOnTheCellphoneDialog = (e) => {
    e.preventDefault()
    setNotOnTheCellphoneDialogOpen(true)
  }

  const closeNotOnTheCellphoneDialog = () => {
    setNotOnTheCellphoneDialogOpen(false)
  }

  const openFaceRecognitionDialog = () => {
    let issetLegalReponsible = stepForMinor.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do responsável legal")
    if (issetLegalReponsible) {
      const fieldId = issetLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formStateValues[`ofield-${fieldId}`] && formStateValues[`ofield-${fieldId}`].length > 0)
        setLegalResponsible(true)
    }
    let issetOtherLegalReponsible = stepForOther.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do representante legal")
    if (issetOtherLegalReponsible) {
      const fieldId = issetOtherLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formStateValues[`ofield-${fieldId}`] && formStateValues[`ofield-${fieldId}`].length > 0)
        setLegalRepresentant(true)
    }

    setFaceRecognitionDialogOpen(true)
    setFaceRecognitionDialogStep(1)
  }

  const startFaceRecognition = () => {
    props.addFaceRecognitionStepLimitAttemp()
    setFaceRecognitionDialogStep(2)
  }

  const closeFaceRecognitionDialog = () => {
    setFaceRecognitionDialogOpen(false)
    setFaceRecognitionDialogStep(1)
  }

  const openDocumentRecognitionDialog = () => {
    let issetLegalReponsible = stepForMinor.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do responsável legal")
    if (issetLegalReponsible) {
      const fieldId = issetLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formStateValues[`ofield-${fieldId}`] && formStateValues[`ofield-${fieldId}`].length > 0)
        setLegalResponsible(true)
    }
    let issetOtherLegalReponsible = stepForOther.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do representante legal")
    if (issetOtherLegalReponsible) {
      const fieldId = issetOtherLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formStateValues[`ofield-${fieldId}`] && formStateValues[`ofield-${fieldId}`].length > 0)
        setLegalRepresentant(true)
    }

    setDocumentRecognitionDialogOpen(true)
    setDocumentRecognitionDialogStep(1)
  }

  const startDocumentRecognition = () => {
    setDocumentRecognitionDialogStep(2)
  }

  const closeDocumentRecognitionDialog = () => {
    setDocumentRecognitionDialogOpen(false)
    setDocumentRecognitionDialogStep(1)
  }

  const confirmDocumentRecognition = () => {
    Mexx2DOConsole.log("=== confirmDocumentRecognition ===")
    
    setNotificationMessage('Verificação de documento realizada com sucesso!')
    setNotificationVariant('success')
    setOpenNotification(true)
    closeDocumentRecognitionDialog()
    setLoading(false)
    setDocumentInteractionOccurred(false)
    props.completeDialogDocumentRecognitionStep(true)
  }

  const handleBackToStart = () => {
    setStartForm(false)
  }

    const onCloseDocumentExistsDialog = _ => {
    setDocumentExistsDialogOpen(false)
    setDocumentExistsProtocol('0000000')
    setDocumentExistsOpeningDate('00/00/0000 00:00:00')
  }

  const onCloseEmailExistsDialog = _ => {
    setEmailExistsDialogOpen(false)
    setEmailExistsProtocol('0000000')
    setEmailExistsOpeningDate('00/00/0000 00:00:00')
  }

  const onCloseDocumentPepDialog = _ => {
    setDocumentPepDialogOpen(false)
    setDocumentPep('000.000.000-00')
  }

  const onCloseDocumentBlacklistDialog = _ => {
    setDocumentBlacklistDialogOpen(false)
    setDocumentBlacklist('000.000.000-00')
  }

  const onOpenMyDataDialog = () => {
    setMyDataDialogOpen(true)
  }

  const onCloseMyDataDialog = () => {
    setMyDataDialogOpen(false)
  }

  const handleErrorFaceLivenessSession = _ => setApiError(true)

  const getFaceLivenessSessionResults = async (data) => {
    setReferenceimage(data.base64image)
    setUrlreferenceimage(data.imagetempurl)
    setLoading(false)
    setOpenNotification(true)
    setNotificationMessage('Verificação de vida realizada com sucesso!')
    setNotificationVariant('success')
    closeFaceRecognitionDialog()
    buttonCompletedRef?.current?.handleComplete()
	}

  const showSnackbar = (type, message) => {
    setLoading(false)
    setOpenNotification(true)
    setNotificationMessage(message)
    setNotificationVariant(type)
  }

  const closeNotification = _ => setOpenNotification(false)

  const changeButtonLabel = _ => {}

  const getFirstOrganization = async () => {
    setLoading(true)

    let response = await getConfigurationsByRoute("desativar-2fa-v2")
    if (response && response.success) {
      if (response.data != null) {
        props.setCalledServiceSettingsStepsData(response.data)
      }
      setLoading(false)
    } else {
      setLoading(false)
      setApiError(false)
    }
  }

  const formatOptions = (organizationField) => {
    if (organizationField.organizationfieldoptionsvalues && organizationField.organizationfieldoptionsvalues.length > 0) {
      organizationField.organizationfieldoptionsvalues = organizationField.organizationfieldoptionsvalues.sort((a, b) => a.id - b.id)
    }
    return organizationField
  }

  const validateForMineOption = (formValues) => {
    if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
      let stepForWho = props.calledServiceSettingsStep.steps?.find(element => element.step === 1 && element.calledservicesettingsorganizationfield?.label === "Para quem é esta solicitação?")
      if (stepForWho) {
        let forMineOption = stepForWho?.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues?.find(option => option.name === "Para mim, eu sou o titular da conta")
        if (forMineOption && formValues[`ofield-${stepForWho.calledservicesettingsorganizationfield.id}`] && forMineOption.id == formValues[`ofield-${stepForWho.calledservicesettingsorganizationfield.id}`])
          return true
      }
    }
    return false
  }

  const validateForMinorOption = (formValues) => {
    if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
      let stepForWho = props.calledServiceSettingsStep.steps?.find(element => element.step === 1 && element.calledservicesettingsorganizationfield?.label === "Para quem é esta solicitação?")
      if (stepForWho) {
        let forMinorOption = stepForWho?.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues?.find(option => option.name === "Para uma criança ou adolescente, sou o responsável legal")
        if (forMinorOption && formValues[`ofield-${stepForWho.calledservicesettingsorganizationfield.id}`] && forMinorOption.id == formValues[`ofield-${stepForWho.calledservicesettingsorganizationfield.id}`])
          return true
      }
    }
    return false
  }

  const validateForOtherOption = (formValues) => {
    if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
      let stepForWho = props.calledServiceSettingsStep.steps?.find(element => element.step === 1 && element.calledservicesettingsorganizationfield?.label === "Para quem é esta solicitação?")
      if (stepForWho) {
        let forOtherOption = stepForWho?.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues?.find(option => option.name === "Para um terceiro, sou o representante legal do titular da conta")
        if (forOtherOption && formValues[`ofield-${stepForWho.calledservicesettingsorganizationfield.id}`] && forOtherOption.id == formValues[`ofield-${stepForWho.calledservicesettingsorganizationfield.id}`])
          return true
      }
    }
    return false
  }

  const validateForMinorOrOtherOption = (formValues) => {
    if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
      let stepForWho = props.calledServiceSettingsStep.steps?.find(element => element.step === 1 && element.calledservicesettingsorganizationfield?.label === "Para quem é esta solicitação?")
      if (stepForWho) {
        let forOtherOption = stepForWho?.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues?.find(option => option.name === "Para um terceiro, sou o representante legal do titular da conta")
        let forMinorOption = stepForWho?.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues?.find(option => option.name === "Para uma criança ou adolescente, sou o responsável legal")
        if (forOtherOption && forMinorOption && formValues[`ofield-${stepForWho.calledservicesettingsorganizationfield.id}`] && (forMinorOption.id == formValues[`ofield-${stepForWho.calledservicesettingsorganizationfield.id}`] || forOtherOption.id == formValues[`ofield-${stepForWho.calledservicesettingsorganizationfield.id}`]))
          return true
      }
    }
    return false
  }

  const handleCompleteButtonRef = _ => {
    buttonCompletedRef?.current?.handleComplete()
  }

  const handleResetFormSteps = () => {
    //requestCameraPermission()
    props.resetDataAlterEmailStepForMineConfirmEmail()
    props.resetDataDialogDocumentRecognitionStep()
    props.resetCalledServiceSettingsStepsData()
    props.resetCreatedCalledData()
    props.resetTriggerResetSteps()
    window.location.reload()
    props.resetStepper()
    props.resetFaceRecognitionStep()
    setUrlreferenceimage("")
    setReferenceimage("")
  }
  const resetFormSteps = useCallback(handleResetFormSteps, [])

  const steps = [
    {
      label: 'Informações gerais',
      showCondition: () => true,
      components: [
        {
          component: StepGeneralInfo,
          condition: () => true,
          completeCondition: async (formValues) => {
            let validated = true

            if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
              let stepData = props.calledServiceSettingsStep.steps.filter(element => element.step === 1).sort((a, b) => a.order - b.order)

              stepData.forEach(element => {
                const fieldId = element.calledservicesettingsorganizationfield?.id
                const organizationfieldtype = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype

                if (organizationfieldtype === 2) {
                  if(!isNumberAndGreaterThanZero(formValues[`ofield-${fieldId}`])) {
                    validated = false
                    methods.setError(`ofield-${fieldId}`, { type: 'manual', message: 'Uma opção deve ser selecionada para avançar' })
                  } else
                    methods.clearErrors(`ofield-${fieldId}`)
                } else if (organizationfieldtype === 4) {
                  if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].id && formValues[`ofield-${fieldId}`].id > 0)
                    methods.clearErrors(`ofield-${fieldId}`)
                  else {
                    validated = false
                    methods.setError(`ofield-${fieldId}`, { type: 'manual', message: 'Selecione um serviço para prosseguir' })
                  }
                }
              });
            }

            return validated
          }
        }
      ],
    },
    {
      label: 'Dados pessoais',
      showCondition: () => true,
      components: [
        {
          component: StepForMine,
          condition: (formValues) => validateForMineOption(formValues),
          completeCondition: async (formValues) => {
            let validUsername = false;
            let validDocument = false;
            let validEmail = false;
            let validConfirmEmail = false;
            let codeConfirmEmailDialogHasCompleted = props.alterEmailStepForMine != null && props.alterEmailStepForMine.isCompleted != null ? props.alterEmailStepForMine.isCompleted : false;
            let codeConfirmEmailLastEmail = props.alterEmailStepForMine != null && props.alterEmailStepForMine.lastEmail != null ? props.alterEmailStepForMine.lastEmail : '';

            if (StringUtils.isNullOrEmpty(formValues.name))
              methods.setError('name', { type: 'manual', message: 'Preencha o nome para avançar' })
            else {
              validUsername = true
              methods.clearErrors('name')
            }

            if (StringUtils.isNullOrEmpty(formValues.document))
              methods.setError('document', { type: 'manual', message: 'Preencha o CPF para avançar' })
            else if (formValues.document.length !== 14 || !validateCpf(formValues.document))
              methods.setError('document', { type: 'manual', message: 'CPF inválido' })
            else {
              validDocument = true
              methods.clearErrors('document')
            }

            if (StringUtils.isNullOrEmpty(formValues.email))
              methods.setError('email', { type: 'manual', message: 'Confirme o e-mail para avançar' })
            else if (!isEmail(formValues.email))
              methods.setError('email', { type: 'manual', message: 'Preencha um e-mail válido para avançar' })
            else {
              validEmail = true
              methods.clearErrors('email')
            }

            if (formValues.email != formValues.confirmEmail)
              methods.setError('confirmEmail', { type: 'manual', message: 'Os e-mails digitados são diferentes' })
            else if (StringUtils.isNullOrEmpty(formValues.confirmEmail))
              methods.setError('confirmEmail', { type: 'manual', message: 'Os e-mails digitados são diferentes' })
            else {
              validConfirmEmail = true
              methods.clearErrors('confirmEmail')
            }

            if (!validUsername || !validDocument || !validEmail || !validConfirmEmail)
              return false

            let data = new FormData()
            data.append('calledservicesettingsid', props.calledServiceSettingsStep.calledservicesettings.calledservicesettingsid)
            data.append('username', formValues.name)
            data.append('useremail', formValues.email)
            data.append('userdocument', formValues.document)
            data.append('hirerid', props.calledServiceSettingsStep.calledservicesettings.hirerid)
            let response = await validateExternalCalled(data)
            if (response && response.errors && response.errors[0]) {
              let error = response.errors[0]
              if (error === 'PEP_Error') {
                setDocumentPep(formValues.document)
                setPepExistsFormValidationError(true)
                return false
              } else if (error === 'Blacklist_Error') {
                setDocumentBlacklist(formValues.document)
                setBlacklistExistsFormValidationError(true)
                return false
              } else if (error === 'Called_Exists') {
                const openingDate = moment(response.data.openingdate).format('DD/MM/YYYY HH:mm')
                const calledId = response.data.calledid
                if (response.errors.length > 1 && response.errors[1] == 'Email_Validation') {
                  setEmailExistsProtocol(calledId)
                  setEmailExistsOpeningDate(openingDate)
                  setDocumentExistsFormValidationError(true)
                } else {
                  setDocumentExistsProtocol(calledId)
                  setDocumentExistsOpeningDate(openingDate)
                  setDocumentExistsFormValidationError(true)
                }
                return false
              } else if (error === 'Validation_Error') {
                setDynamicFormValidationError(true)
                if (response.data) {
                  setDynamicFormValidationErrorDescription(response.data)
                }
                return false
              }
            }

            if (validUsername && validDocument && validEmail && validConfirmEmail && (!codeConfirmEmailDialogHasCompleted || (codeConfirmEmailDialogHasCompleted && codeConfirmEmailLastEmail !== formValues.email))) {
              codeConfirmEmailDialogHasCompleted = false
              props.completeDialogAlterEmailStepForMineConfirmEmail(false)
              props.openDialogAlterEmailStepForMineConfirmEmail(true)
              
            }

            return validUsername && validDocument && validEmail && validConfirmEmail && codeConfirmEmailDialogHasCompleted
          }
        },
        {
          component: StepForMinor,
          condition: (formValues) => validateForMinorOption(formValues),
          completeCondition: async (formValues) => {
            let validated = true;
            let validUsername = false;
            let validDocument = false;
            let validEmail = false;
            let validConfirmEmail = false;
            let codeConfirmEmailDialogHasCompleted = props.alterEmailStepForMine != null && props.alterEmailStepForMine.isCompleted != null ? props.alterEmailStepForMine.isCompleted : false;
            let codeConfirmEmailLastEmail = props.alterEmailStepForMine != null && props.alterEmailStepForMine.lastEmail != null ? props.alterEmailStepForMine.lastEmail : '';

            if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
              let stepData = props.calledServiceSettingsStep.steps.filter(element => element.step === 3).sort((a, b) => a.order - b.order)

              stepData.forEach(element => {
                const label = element.calledservicesettingsorganizationfield?.label
                const fieldId = element.calledservicesettingsorganizationfield?.id
                const organizationfieldtype = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype
                const message = label == "Nome do responsável legal" ? "Preencha o nome para avançar" : "Preencha o CPF para avançar"

                if (organizationfieldtype === 1) {
                  if(StringUtils.isNullOrEmpty(formValues[`ofield-${fieldId}`])) {
                    validated = false
                    methods.setError(`ofield-${fieldId}`, { type: 'manual', message: message })
                  } else if(label == "CPF do responsável legal" && (formValues[`ofield-${fieldId}`].length !== 14 || !validateCpf(formValues[`ofield-${fieldId}`]))) {
                    validated = false
                    methods.setError(`ofield-${fieldId}`, { type: 'manual', message: 'CPF inválido' })
                  } else
                    methods.clearErrors(`ofield-${fieldId}`)
                }
              });
            }

            if (StringUtils.isNullOrEmpty(formValues.name))
              methods.setError('name', { type: 'manual', message: 'Preencha o nome para avançar' })
            else {
              validUsername = true
              methods.clearErrors('name')
            }

            if (StringUtils.isNullOrEmpty(formValues.document))
              methods.setError('document', { type: 'manual', message: 'Preencha o CPF para avançar' })
            else if (formValues.document.length !== 14 || !validateCpf(formValues.document))
              methods.setError('document', { type: 'manual', message: 'CPF inválido' })
            else {
              validDocument = true
              methods.clearErrors('document')
            }

            if (StringUtils.isNullOrEmpty(formValues.email))
              methods.setError('email', { type: 'manual', message: 'Confirme o e-mail para avançar' })
            else if (!isEmail(formValues.email))
              methods.setError('email', { type: 'manual', message: 'Preencha um e-mail válido para avançar' })
            else {
              validEmail = true
              methods.clearErrors('email')
            }

            if (formValues.email != formValues.confirmEmail)
              methods.setError('confirmEmail', { type: 'manual', message: 'Os e-mails digitados são diferentes' })
            else if (StringUtils.isNullOrEmpty(formValues.confirmEmail))
              methods.setError('confirmEmail', { type: 'manual', message: 'Os e-mails digitados são diferentes' })
            else {
              validConfirmEmail = true
              methods.clearErrors('confirmEmail')
            }

            if (!validUsername || !validDocument || !validated || !validEmail || !validConfirmEmail)
              return false

            let data = new FormData()
            data.append('calledservicesettingsid', props.calledServiceSettingsStep.calledservicesettings.calledservicesettingsid)
            data.append('username', formValues.name)
            data.append('useremail', formValues.email)
            data.append('userdocument', formValues.document)
            data.append('hirerid', props.calledServiceSettingsStep.calledservicesettings.hirerid)
            let response = await validateExternalCalled(data)
            if (response && response.errors && response.errors[0]) {
              let error = response.errors[0]
              if (error === 'PEP_Error') {
                setDocumentPep(formValues.document)
                setPepExistsFormValidationError(true)
                return false
              } else if (error === 'Blacklist_Error') {
                setDocumentBlacklist(formValues.document)
                setBlacklistExistsFormValidationError(true)
                return false
              } else if (error === 'Called_Exists') {
                const openingDate = moment(response.data.openingdate).format('DD/MM/YYYY HH:mm')
                const calledId = response.data.calledid
                if (response.errors.length > 1 && response.errors[1] == 'Email_Validation') {
                  setEmailExistsProtocol(calledId)
                  setEmailExistsOpeningDate(openingDate)
                  setDocumentExistsFormValidationError(true)
                } else {
                  setDocumentExistsProtocol(calledId)
                  setDocumentExistsOpeningDate(openingDate)
                  setDocumentExistsFormValidationError(true)
                }
                return false
              } else if (error === 'Validation_Error') {
                setDynamicFormValidationError(true)
                if (response.data) {
                  setDynamicFormValidationErrorDescription(response.data)
                }
                return false
              }
            }

            if (validUsername && validated && validDocument && validEmail && validConfirmEmail && (!codeConfirmEmailDialogHasCompleted || (codeConfirmEmailDialogHasCompleted && codeConfirmEmailLastEmail !== formValues.email))) {
              codeConfirmEmailDialogHasCompleted = false
              props.completeDialogAlterEmailStepForMineConfirmEmail(false)
              props.openDialogAlterEmailStepForMineConfirmEmail(true)
            }

            return validUsername && validated && validDocument && validEmail && validConfirmEmail && codeConfirmEmailDialogHasCompleted
          }
        },
        {
          component: StepForOther,
          condition: (formValues) => validateForOtherOption(formValues),
          completeCondition: async (formValues) => {
            let validated = true;
            let validUsername = false;
            let validDocument = false;
            let validEmail = false;
            let validConfirmEmail = false;
            let codeConfirmEmailDialogHasCompleted = props.alterEmailStepForMine != null && props.alterEmailStepForMine.isCompleted != null ? props.alterEmailStepForMine.isCompleted : false;
            let codeConfirmEmailLastEmail = props.alterEmailStepForMine != null && props.alterEmailStepForMine.lastEmail != null ? props.alterEmailStepForMine.lastEmail : '';

            if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
              let stepData = props.calledServiceSettingsStep.steps.filter(element => element.step === 4).sort((a, b) => a.order - b.order)

              stepData.forEach(element => {
                const label = element.calledservicesettingsorganizationfield?.label
                const fieldId = element.calledservicesettingsorganizationfield?.id
                const organizationfieldtype = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype
                const message = label == "Nome do representante legal" ? "Preencha o nome para avançar" : "Preencha o CPF para avançar"

                if (organizationfieldtype === 1) {
                  if(StringUtils.isNullOrEmpty(formValues[`ofield-${fieldId}`])) {
                    validated = false
                    methods.setError(`ofield-${fieldId}`, { type: 'manual', message: message })
                  } else if(label == "CPF do representante legal" && (formValues[`ofield-${fieldId}`].length !== 14 || !validateCpf(formValues[`ofield-${fieldId}`]))) {
                    validated = false
                    methods.setError(`ofield-${fieldId}`, { type: 'manual', message: 'CPF inválido' })
                  } else
                    methods.clearErrors(`ofield-${fieldId}`)
                }
              });
            }

            if (StringUtils.isNullOrEmpty(formValues.name))
              methods.setError('name', { type: 'manual', message: 'Preencha o nome para avançar' })
            else {
              validUsername = true
              methods.clearErrors('name')
            }

            if (StringUtils.isNullOrEmpty(formValues.document))
              methods.setError('document', { type: 'manual', message: 'Preencha o CPF para avançar' })
            else if (formValues.document.length !== 14 || !validateCpf(formValues.document))
              methods.setError('document', { type: 'manual', message: 'CPF inválido' })
            else {
              validDocument = true
              methods.clearErrors('document')
            }

            if (StringUtils.isNullOrEmpty(formValues.email))
              methods.setError('email', { type: 'manual', message: 'Confirme o e-mail para avançar' })
            else if (!isEmail(formValues.email))
              methods.setError('email', { type: 'manual', message: 'Preencha um e-mail válido para avançar' })
            else {
              validEmail = true
              methods.clearErrors('email')
            }

            if (formValues.email != formValues.confirmEmail)
              methods.setError('confirmEmail', { type: 'manual', message: 'Os e-mails digitados são diferentes' })
            else if (StringUtils.isNullOrEmpty(formValues.confirmEmail))
              methods.setError('confirmEmail', { type: 'manual', message: 'Os e-mails digitados são diferentes' })
            else {
              validConfirmEmail = true
              methods.clearErrors('confirmEmail')
            }

            if (!validUsername || !validated || !validDocument || !validEmail || !validConfirmEmail)
              return false

            let data = new FormData()
            data.append('calledservicesettingsid', props.calledServiceSettingsStep.calledservicesettings.calledservicesettingsid)
            data.append('username', formValues.name)
            data.append('useremail', formValues.email)
            data.append('userdocument', formValues.document)
            data.append('hirerid', props.calledServiceSettingsStep.calledservicesettings.hirerid)
            let response = await validateExternalCalled(data)
            if (response && response.errors && response.errors[0]) {
              let error = response.errors[0]
              if (error === 'PEP_Error') {
                setDocumentPep(formValues.document)
                setPepExistsFormValidationError(true)
                return false
              } else if (error === 'Blacklist_Error') {
                setDocumentBlacklist(formValues.document)
                setBlacklistExistsFormValidationError(true)
                return false
              } else if (error === 'Called_Exists') {
                const openingDate = moment(response.data.openingdate).format('DD/MM/YYYY HH:mm')
                const calledId = response.data.calledid
                if (response.errors.length > 1 && response.errors[1] == 'Email_Validation') {
                  setEmailExistsProtocol(calledId)
                  setEmailExistsOpeningDate(openingDate)
                  setDocumentExistsFormValidationError(true)
                } else {
                  setDocumentExistsProtocol(calledId)
                  setDocumentExistsOpeningDate(openingDate)
                  setDocumentExistsFormValidationError(true)
                }
                return false
              } else if (error === 'Validation_Error') {
                setDynamicFormValidationError(true)
                if (response.data) {
                  setDynamicFormValidationErrorDescription(response.data)
                }
                return false
              }
            }

            if (validUsername && validated && validDocument && validEmail && validConfirmEmail && (!codeConfirmEmailDialogHasCompleted || (codeConfirmEmailDialogHasCompleted && codeConfirmEmailLastEmail !== formValues.email))) {
              codeConfirmEmailDialogHasCompleted = false
              props.completeDialogAlterEmailStepForMineConfirmEmail(false)
              props.openDialogAlterEmailStepForMineConfirmEmail(true)
            }

            return validUsername && validated && validDocument && validEmail && validConfirmEmail && codeConfirmEmailDialogHasCompleted
          }
        },
      ],
    },
    {
      label: 'Leitura do documento',
      showCondition: () => true,
      components: [
        {
          component: StepDocumentRecognition,
          condition: () => true,
          completeCondition: async (formValues) => {
            Mexx2DOConsole.log("=== Leitura do documento ===")
            Mexx2DOConsole.log(props.documentRecognitionStep)
            Mexx2DOConsole.log(formValues)
            let validated = true;
            let documentRecognitionDialogCompleted = props.documentRecognitionStep != null && props.documentRecognitionStep.isCompleted != null ? props.documentRecognitionStep.isCompleted : false;
            let documentRecognitionDialogStep = props.documentRecognitionStep != null && props.documentRecognitionStep.documentNifStep != null ? props.documentRecognitionStep.documentNifStep : 1;

            if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
              let stepData = props.calledServiceSettingsStep.steps.filter(element => element.step === 5).sort((a, b) => a.order - b.order)

              let sField = stepData.find(element => element.fieldtype == 2 && element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype === 4 && element?.calledservicesettingsorganizationfield?.label == "Documento")
              let recognitionUField = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.filelimit == 2 && element?.calledservicesettingsfileupload.label == "Documento - Reconhecimento")
              let recognitionNIFUField = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.filelimit == 2 && element?.calledservicesettingsfileupload.label == "Documento - Reconhecimento NIF")
              if (sField != null) {
                const fieldId = sField.calledservicesettingsorganizationfield?.id
                const options = sField.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
                const formattedOptions = formatOptions(options)
                sField.calledservicesettingsorganizationfield.organizationfield.organizationfieldoptionsvalues = formattedOptions
                if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].id && formValues[`ofield-${fieldId}`].name != null && formValues[`ofield-${fieldId}`].name != 'Selecione o tipo de documento a ser utilizado') {
                  methods.clearErrors(`ofield-${fieldId}`)
                } else {
                  validated = false
                  methods.setError(`ofield-${fieldId}`, { type: 'manual', message: 'Selecione um tipo de documento para avançar' })
                }

                const recognitionUFieldId = recognitionUField?.calledservicesettingsfileupload?.id
                const recognitionNIFUFieldId = recognitionNIFUField?.calledservicesettingsfileupload?.id
                Mexx2DOConsole.log(recognitionUFieldId)
                Mexx2DOConsole.log(recognitionNIFUFieldId)
                Mexx2DOConsole.log(formValues[`files-${recognitionUFieldId}`])
                Mexx2DOConsole.log(formValues[`files-${recognitionNIFUFieldId}`])
                if (documentRecognitionDialogCompleted == false && formValues[`ofield-${fieldId}`] != null && formValues[`ofield-${fieldId}`].name != null && (formValues[`ofield-${fieldId}`].name !== 'Documento digital de identificação' && formValues[`ofield-${fieldId}`].name !== 'Selecione o tipo de documento a ser utilizado' && formValues[`ofield-${fieldId}`].name !== 'Carteira de Identidade (RG) + Comprovante de Inscrição no CPF')) {
                  props.completeDialogDocumentRecognitionStep(false)
                  documentRecognitionDialogCompleted = false
                  validated = false
                  openDocumentRecognitionDialog()
                } else if (documentRecognitionDialogCompleted == false && formValues[`ofield-${fieldId}`] != null && formValues[`ofield-${fieldId}`].name != null && (formValues[`ofield-${fieldId}`].name !== 'Documento digital de identificação' && formValues[`ofield-${fieldId}`].name !== 'Selecione o tipo de documento a ser utilizado' && formValues[`ofield-${fieldId}`].name !== 'Carteira de Identidade (RG) + Comprovante de Inscrição no CPF') && recognitionUFieldId != null && formValues[`files-${recognitionUFieldId}`] != null && formValues[`files-${recognitionUFieldId}`].length == 2 && formValues[`files-${recognitionUFieldId}`][0] != null && formValues[`files-${recognitionUFieldId}`][0].src != null && formValues[`files-${recognitionUFieldId}`][0].src.length > 0) {
                  props.completeDialogDocumentRecognitionStep(true)
                  documentRecognitionDialogCompleted = true
                } else if (formValues[`ofield-${fieldId}`] != null && formValues[`ofield-${fieldId}`].name != null && formValues[`ofield-${fieldId}`].name === 'Carteira de Identidade (RG) + Comprovante de Inscrição no CPF') {
                  if (documentRecognitionDialogStep === 1 && !(recognitionUFieldId != null && formValues[`files-${recognitionUFieldId}`] != null && formValues[`files-${recognitionUFieldId}`].length == 2 && formValues[`files-${recognitionUFieldId}`][0] != null && formValues[`files-${recognitionUFieldId}`][0].src != null && formValues[`files-${recognitionUFieldId}`][0].src.length > 0)) {
                    props.completeDialogDocumentRecognitionStep(false)
                    documentRecognitionDialogCompleted = false
                    validated = false
                    openDocumentRecognitionDialog()
                  } else if (documentRecognitionDialogStep === 1 && documentRecognitionDialogCompleted && recognitionUFieldId != null && formValues[`files-${recognitionUFieldId}`] != null && formValues[`files-${recognitionUFieldId}`].length == 2 && formValues[`files-${recognitionUFieldId}`][0] != null && formValues[`files-${recognitionUFieldId}`][0].src != null && formValues[`files-${recognitionUFieldId}`][0].src.length > 0) {
                    props.completeDialogDocumentRecognitionStep(false)
                    documentRecognitionDialogCompleted = false
                    validated = false
                    props.nextDialogDocumentRecognitionStep()
                  } else if (documentRecognitionDialogStep === 2 && !(recognitionNIFUFieldId != null && formValues[`files-${recognitionNIFUFieldId}`] != null && formValues[`files-${recognitionNIFUFieldId}`].length == 2 && formValues[`files-${recognitionNIFUFieldId}`][0] != null && formValues[`files-${recognitionNIFUFieldId}`][0].src != null && formValues[`files-${recognitionNIFUFieldId}`][0].src.length > 0)) {
                    
                    props.completeDialogDocumentRecognitionStep(false)
                    documentRecognitionDialogCompleted = false
                    validated = false
                    openDocumentRecognitionDialog()
                  } else if (documentRecognitionDialogStep === 2 && documentRecognitionDialogCompleted && recognitionNIFUFieldId != null && formValues[`files-${recognitionNIFUFieldId}`] != null && formValues[`files-${recognitionNIFUFieldId}`].length == 2 && formValues[`files-${recognitionNIFUFieldId}`][0] != null && formValues[`files-${recognitionNIFUFieldId}`][0].src != null && formValues[`files-${recognitionNIFUFieldId}`][0].src.length > 0) {
                    props.completeDialogDocumentRecognitionStep(true)
                    documentRecognitionDialogCompleted = true
                  }
                }
              }

              let uField = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.filelimit == 1)
              if (sField != null && uField != null) {
                const sFieldId = sField.calledservicesettingsorganizationfield?.id
                const uFieldId = uField.calledservicesettingsfileupload?.id
                if (formValues[`ofield-${sFieldId}`] != null && formValues[`ofield-${sFieldId}`].name != null && formValues[`ofield-${sFieldId}`].name === 'Documento digital de identificação' && formValues[`files-${uFieldId}`] && formValues[`files-${uFieldId}`].length > 0) {
                  props.completeDialogDocumentRecognitionStep(true)
                  documentRecognitionDialogCompleted = true
                } else if (formValues[`ofield-${sFieldId}`] != null && formValues[`ofield-${sFieldId}`].name != null && formValues[`ofield-${sFieldId}`].name === 'Documento digital de identificação' && (formValues[`files-${uFieldId}`] == null || (formValues[`files-${uFieldId}`] && formValues[`files-${uFieldId}`].length == 0))) {
                  props.completeDialogDocumentRecognitionStep(false)
                  documentRecognitionDialogCompleted = false
                  validated = false
                  methods.setError(`files-${uFieldId}`, { type: 'manual', message: 'Insira um documento para avançar' })
                }
              }
            }
            
            if (!validated || !documentRecognitionDialogCompleted)
              return false

            let files = []
            Mexx2DOConsole.log("=== StepDocumentRecognition - Files ===")
            Mexx2DOConsole.log(formValues)
            for (const [key, value] of Object.entries(formValues)) {
              if (key.startsWith("files-")) {
                Mexx2DOConsole.log("File send forearch")
                Mexx2DOConsole.log(key)
                Mexx2DOConsole.log(value)
                let keyStringSplit = key.split('-')
                let filesList = value.map(fileItem => {
                  let finalFile = fileItem
                  if (fileItem && fileItem.side && fileItem.side.length > 0 && fileItem.src && fileItem.src.length > 0) {
                    let guidId = uuidv4()
                    let uuidNoHyphens = guidId.replace(/-/g, '');
                    let numericGuid = '';
                    for (let i = 0; i < uuidNoHyphens.length; i++) {
                        numericGuid += uuidNoHyphens.charCodeAt(i).toString();
                    }
                    finalFile = base64ToFile(fileItem.src, `${numericGuid}_document_${fileItem.side}`)
                  }

                  return finalFile
                })
                files.push({ id: keyStringSplit[1], files: [...filesList] })
              }
            }
            Mexx2DOConsole.log(files)

            let bigFile = []
            let invalidFormatFile = []
            let fileMaxSize = 1024 * 50 // 50 MB

            files.forEach(async item => {
              item.files.forEach(fileItem => {
                Mexx2DOConsole.log("isInvalidFileType")
                Mexx2DOConsole.log(isInvalidFileType(fileItem.type))
                if (isInvalidFileType(fileItem.type))
                  invalidFormatFile.push(fileItem.name)

                let fileKbSize = Math.round((fileItem.size / 1024))
                if (fileKbSize > fileMaxSize)
                  bigFile.push(fileItem.name)
              })
            });

            if (bigFile.length > 0 || invalidFormatFile.length > 0) {
              let errorMessage = ''

              if (bigFile.length > 0)
                errorMessage = 'Você não pode enviar arquivos com o tamanho maior que 50MB'
              if (bigFile.length > 0 && invalidFormatFile.length > 0)
                errorMessage += '\n'
              if (invalidFormatFile.length > 0) {
                errorMessage += 'São aceitos somente arquivos no formato: \n'
                errorMessage += 'Imagens - BMP, GIF, JPG, JPEG, PNG e TIFF; \n'
                errorMessage += 'Documentos DOC, DOCX, ODP, ODS, ODT, PDF, PPT, PPTX e RTF; \n'
              }
              setLoading(false)
              showSnackbar('error', errorMessage)
              validated = false
            }

            return validated && documentRecognitionDialogCompleted;
          }
        }
      ]
    },
    {
      label: 'Leitura do rosto',
      showCondition: () => true,
      components: [
        {
          component: StepFaceRecognition,
          condition: () => true,
          completeCondition: async (formValues) => {
            let faceRecognition = false
            if (urlreferenceimage && urlreferenceimage.length > 0 && referenceimage && referenceimage.length > 0)
              faceRecognition = true
            else
              openFaceRecognitionDialog()

            if (faceRecognition) await stopAllStreams()

            return faceRecognition;
          }
        }
      ]
    },
    {
      label: 'Anexo',
      showCondition: (formValues) => validateForMinorOrOtherOption(formValues),
      components: [
        {
          component: StepAttachmentMinor,
          condition: (formValues) => validateForMinorOption(formValues),
          completeCondition: async (formValues) => {
            let validated = true;
            let documentRecognitionDialogCompleted = false;

            Mexx2DOConsole.log("StepAttachmentMinor")
            
            if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
              let stepData = props.calledServiceSettingsStep.steps.filter(element => element.step === 7).sort((a, b) => a.order - b.order)

              let sField = stepData.find(element => element.fieldtype == 2 && element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype === 4 && element?.calledservicesettingsorganizationfield?.label == "Anexo - Para menor")
              if (sField != null) {
                const fieldId = sField.calledservicesettingsorganizationfield?.id
                const options = sField.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
                const formattedOptions = formatOptions(options)
                sField.calledservicesettingsorganizationfield.organizationfield.organizationfieldoptionsvalues = formattedOptions
                if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].id && formValues[`ofield-${fieldId}`].name != null && formValues[`ofield-${fieldId}`].name != 'Selecione o tipo de documento a ser utilizado') {
                  methods.clearErrors(`ofield-${fieldId}`)
                } else {
                  validated = false
                  methods.setError(`ofield-${fieldId}`, { type: 'manual', message: 'Selecione um tipo de documento para avançar' })
                }
              }

              let uField = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.label == "Anexo - Para menor")
              let uFieldNIF = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.label == "Anexo - Para menor NIF")
              Mexx2DOConsole.log(uFieldNIF)
              if (sField != null && uField != null) {
                Mexx2DOConsole.log("sField != null && uField != null")
                const sFieldId = sField.calledservicesettingsorganizationfield?.id
                const uFieldId = uField.calledservicesettingsfileupload?.id
                if (formValues[`ofield-${sFieldId}`] != null && formValues[`ofield-${sFieldId}`].name != null && (formValues[`ofield-${sFieldId}`].name !== 'Selecione o tipo de documento a ser utilizado' && formValues[`ofield-${sFieldId}`].name !== 'Carteira de Identidade da criança ou adolescente + comprovante de inscrição no CPF') && formValues[`files-${uFieldId}`] && formValues[`files-${uFieldId}`].length > 0) {
                  props.completeDialogDocumentRecognitionStep(true)
                  documentRecognitionDialogCompleted = true
                } else if (formValues[`ofield-${sFieldId}`] != null && formValues[`ofield-${sFieldId}`].name != null && (formValues[`ofield-${sFieldId}`].name !== 'Selecione o tipo de documento a ser utilizado' && formValues[`ofield-${sFieldId}`].name !== 'Carteira de Identidade da criança ou adolescente + comprovante de inscrição no CPF') && (formValues[`files-${uFieldId}`] == null || (formValues[`files-${uFieldId}`] && formValues[`files-${uFieldId}`].length == 0))) {
                  props.completeDialogDocumentRecognitionStep(false)
                  documentRecognitionDialogCompleted = false
                  validated = false
                  methods.setError(`files-${uFieldId}`, { type: 'manual', message: 'Insira um documento para avançar' })
                } else if (uFieldNIF != null && formValues[`ofield-${sFieldId}`] != null && formValues[`ofield-${sFieldId}`].name != null && formValues[`ofield-${sFieldId}`].name === 'Carteira de Identidade da criança ou adolescente + comprovante de inscrição no CPF') {
                  Mexx2DOConsole.log("uFieldNIFId")
                  const uFieldNIFId = uFieldNIF.calledservicesettingsfileupload?.id
                  if (attachmentForMinordocumentNifStep === 1 && formValues[`files-${uFieldId}`] && formValues[`files-${uFieldId}`].length > 0) {
                    props.completeDialogDocumentRecognitionStep(false)
                    documentRecognitionDialogCompleted = false
                    validated = false
                    props.nextAttachmentForMinorStep()
                  } else if (attachmentForMinordocumentNifStep === 1 && (formValues[`files-${uFieldId}`] == null || (formValues[`files-${uFieldId}`] && formValues[`files-${uFieldId}`].length == 0))) {
                    props.completeDialogDocumentRecognitionStep(false)
                    documentRecognitionDialogCompleted = false
                    validated = false
                    methods.setError(`files-${uFieldId}`, { type: 'manual', message: 'Insira um documento para avançar' })
                  } else if (attachmentForMinordocumentNifStep > 1 && formValues[`files-${uFieldNIFId}`] && formValues[`files-${uFieldNIFId}`].length > 0) {
                    props.completeDialogDocumentRecognitionStep(true)
                    documentRecognitionDialogCompleted = true
                  } else if (attachmentForMinordocumentNifStep > 1 && (formValues[`files-${uFieldNIFId}`] == null || (formValues[`files-${uFieldNIFId}`] && formValues[`files-${uFieldNIFId}`].length == 0))) {
                    props.completeDialogDocumentRecognitionStep(false)
                    documentRecognitionDialogCompleted = false
                    validated = false
                    methods.setError(`files-${uFieldNIFId}`, { type: 'manual', message: 'Insira um documento para avançar' })
                  }
                }
              }
            }

            if (!validated || !documentRecognitionDialogCompleted)
              return false

            let files = []
            Mexx2DOConsole.log("=== StepAttachmentMinor - Files ===")
            Mexx2DOConsole.log(formValues)
            for (const [key, value] of Object.entries(formValues)) {
              if (key.startsWith("files-")) {
                Mexx2DOConsole.log("File send forearch")
                Mexx2DOConsole.log(key)
                Mexx2DOConsole.log(value)
                let keyStringSplit = key.split('-')
                let filesList = value.map(fileItem => {
                  let finalFile = fileItem
                  if (fileItem && fileItem.side && fileItem.side.length > 0 && fileItem.src && fileItem.src.length > 0) {
                    let guidId = uuidv4()
                    let uuidNoHyphens = guidId.replace(/-/g, '');
                    let numericGuid = '';
                    for (let i = 0; i < uuidNoHyphens.length; i++) {
                        numericGuid += uuidNoHyphens.charCodeAt(i).toString();
                    }
                    finalFile = base64ToFile(fileItem.src, `${numericGuid}_document_${fileItem.side}`)
                  }

                  return finalFile
                })
                files.push({ id: keyStringSplit[1], files: [...filesList] })
              }
            }
            Mexx2DOConsole.log(files)

            let bigFile = []
            let invalidFormatFile = []
            let fileMaxSize = 1024 * 50 // 50 MB

            files.forEach(async item => {
              item.files.forEach(fileItem => {
                if (isInvalidFileType(fileItem.type))
                  invalidFormatFile.push(fileItem.name)

                let fileKbSize = Math.round((fileItem.size / 1024))
                if (fileKbSize > fileMaxSize)
                  bigFile.push(fileItem.name)
              })
            });

            if (bigFile.length > 0 || invalidFormatFile.length > 0) {
              let errorMessage = ''

              if (bigFile.length > 0)
                errorMessage = 'Você não pode enviar arquivos com o tamanho maior que 50MB'
              if (bigFile.length > 0 && invalidFormatFile.length > 0)
                errorMessage += '\n'
              if (invalidFormatFile.length > 0) {
                errorMessage += 'São aceitos somente arquivos no formato: \n'
                errorMessage += 'Imagens - BMP, GIF, JPG, JPEG, PNG e TIFF; \n'
                errorMessage += 'Documentos DOC, DOCX, ODP, ODS, ODT, PDF, PPT, PPTX e RTF; \n'
              }
              setLoading(false)
              showSnackbar('error', errorMessage)
              validated = false
            }

            return validated && documentRecognitionDialogCompleted;
          }
        },
        {
          component: StepAttachmentOther,
          condition: (formValues) => validateForOtherOption(formValues),
          completeCondition: async (formValues) => {
            let validated = true;
            let documentRecognitionDialogCompleted = false;
            
            if (props.calledServiceSettingsStep && props.calledServiceSettingsStep.steps && props.calledServiceSettingsStep.steps.length > 0) {
              let stepData = props.calledServiceSettingsStep.steps.filter(element => element.step === 8).sort((a, b) => a.order - b.order)

              let sField = stepData.find(element => element.fieldtype == 2 && element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype === 4 && element?.calledservicesettingsorganizationfield?.label == "Anexo - Para terceiros")
              if (sField != null) {
                const fieldId = sField.calledservicesettingsorganizationfield?.id
                const options = sField.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
                const formattedOptions = formatOptions(options)
                sField.calledservicesettingsorganizationfield.organizationfield.organizationfieldoptionsvalues = formattedOptions
                if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].id && formValues[`ofield-${fieldId}`].name != null && formValues[`ofield-${fieldId}`].name != 'Selecione o tipo de documento a ser utilizado') {
                  methods.clearErrors(`ofield-${fieldId}`)
                } else {
                  validated = false
                  methods.setError(`ofield-${fieldId}`, { type: 'manual', message: 'Selecione um tipo de documento para avançar' })
                }
              }

              let uField = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null)
              if (sField != null && uField != null) {
                const sFieldId = sField.calledservicesettingsorganizationfield?.id
                const uFieldId = uField.calledservicesettingsfileupload?.id
                if (formValues[`ofield-${sFieldId}`] != null && formValues[`ofield-${sFieldId}`].name != null && formValues[`ofield-${sFieldId}`].name  !== 'Selecione o tipo de documento a ser utilizado' && formValues[`files-${uFieldId}`] && formValues[`files-${uFieldId}`].length > 0) {
                  props.completeDialogDocumentRecognitionStep(true)
                  documentRecognitionDialogCompleted = true
                } else if (formValues[`ofield-${sFieldId}`] != null && formValues[`ofield-${sFieldId}`].name != null && formValues[`ofield-${sFieldId}`].name  !== 'Selecione o tipo de documento a ser utilizado' && (formValues[`files-${uFieldId}`] == null || (formValues[`files-${uFieldId}`] && formValues[`files-${uFieldId}`].length == 0))) {
                  props.completeDialogDocumentRecognitionStep(false)
                  documentRecognitionDialogCompleted = false
                  validated = false
                  methods.setError(`files-${uFieldId}`, { type: 'manual', message: 'Insira um documento para avançar' })
                }
              }
            }

            if (!validated || !documentRecognitionDialogCompleted)
              return false

            let files = []
            Mexx2DOConsole.log("=== StepAttachmentOther - Files ===")
            Mexx2DOConsole.log(formValues)
            for (const [key, value] of Object.entries(formValues)) {
              if (key.startsWith("files-")) {
                Mexx2DOConsole.log("File send forearch")
                Mexx2DOConsole.log(key)
                Mexx2DOConsole.log(value)
                let keyStringSplit = key.split('-')
                let filesList = value.map(fileItem => {
                  let finalFile = fileItem
                  if (fileItem && fileItem.side && fileItem.side.length > 0 && fileItem.src && fileItem.src.length > 0) {
                    let guidId = uuidv4()
                    let uuidNoHyphens = guidId.replace(/-/g, '');
                    let numericGuid = '';
                    for (let i = 0; i < uuidNoHyphens.length; i++) {
                        numericGuid += uuidNoHyphens.charCodeAt(i).toString();
                    }
                    finalFile = base64ToFile(fileItem.src, `${numericGuid}_document_${fileItem.side}`)
                  }

                  return finalFile
                })
                files.push({ id: keyStringSplit[1], files: [...filesList] })
              }
            }
            Mexx2DOConsole.log(files)

            let bigFile = []
            let invalidFormatFile = []
            let fileMaxSize = 1024 * 50 // 50 MB

            files.forEach(async item => {
              item.files.forEach(fileItem => {
                if (isInvalidFileType(fileItem.type))
                  invalidFormatFile.push(fileItem.name)

                let fileKbSize = Math.round((fileItem.size / 1024))
                if (fileKbSize > fileMaxSize)
                  bigFile.push(fileItem.name)
              })
            });

            if (bigFile.length > 0 || invalidFormatFile.length > 0) {
              let errorMessage = ''

              if (bigFile.length > 0)
                errorMessage = 'Você não pode enviar arquivos com o tamanho maior que 50MB'
              if (bigFile.length > 0 && invalidFormatFile.length > 0)
                errorMessage += '\n'
              if (invalidFormatFile.length > 0) {
                errorMessage += 'São aceitos somente arquivos no formato: \n'
                errorMessage += 'Imagens - BMP, GIF, JPG, JPEG, PNG e TIFF; \n'
                errorMessage += 'Documentos DOC, DOCX, ODP, ODS, ODT, PDF, PPT, PPTX e RTF; \n'
              }
              setLoading(false)
              showSnackbar('error', errorMessage)
              validated = false
            }

            return validated && documentRecognitionDialogCompleted;
          }
        }
      ]
    },
    {
      label: 'Enviar',
      showCondition: () => true,
      components: [
        {
          component: StepSend,
          condition: () => true,
          completeCondition: async (formValues) => {
            let validated = true
            let dataConfirmDeclaration = false

            if (formValues.dataConfirmDeclaration && formValues.dataConfirmDeclaration[1] === true) {
              dataConfirmDeclaration = true
              methods.clearErrors('dataConfirmDeclaration')
            } else {
              methods.setError('dataConfirmDeclaration', { type: 'manual', message: 'Marque o termo de responsabilidade para prosseguir' })
              return false
            }
            setLoading(true)
            let { name, email, document } = formValues
            let oFields = []
            let oFieldsValuesText = ''
            let oFieldsSuccessText = '<div class="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-0">'

            Mexx2DOConsole.log("field")
            for (const [key, value] of Object.entries(formValues)) {
              if(key.startsWith('ofield-')) {
                let valueData = ''

                let ofieldSubstring = key.split('-')
                
                let organizationFieldData = props.calledServiceSettingsStep.steps.find(element => element.calledservicesettingsorganizationfield?.id == ofieldSubstring[1])

                Mexx2DOConsole.log("=== organizationFieldData ===")
                Mexx2DOConsole.log(`id => ${ofieldSubstring[1]}`)
                Mexx2DOConsole.log(organizationFieldData)
                Mexx2DOConsole.log(value)
                if (value && value.id && value.id > 0) {
                  Mexx2DOConsole.log("organizationFieldData => value.id")
                  oFields.push({ id: organizationFieldData?.calledservicesettingsorganizationfield?.organizationfieldid, value: `${value.id}` })
                } else if (value && value.length > 0) {
                  Mexx2DOConsole.log("organizationFieldData => value")
                  oFields.push({ id: organizationFieldData?.calledservicesettingsorganizationfield?.organizationfieldid, value: value })
                }

                if (organizationFieldData?.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype !== 1 && organizationFieldData?.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues != null && (value && ((value.id && value.id > 0) || (!isNaN(value) && value > 0)))) {
                  let valueDataOptionItem = organizationFieldData?.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues?.find(item => (
                    (value && value.id && value.id > 0 && item.id == value.id) ||
                    (!isNaN(value) && value > 0 && item.id == value)
                  ))
                  if (valueDataOptionItem != null)
                    valueData = valueDataOptionItem.name
                } else
                  valueData = value

                oFieldsValuesText +=`<p>${organizationFieldData?.calledservicesettingsorganizationfield?.label}: '${valueData}'</p>`
                oFieldsSuccessText += `<div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-12 MuiGrid-grid-md-12 MuiGrid-grid-lg-12" style="padding-top: 16px;">
                    <span class="MuiTypography-root MuiTypography-p" style="font-weight: bold;font-size: 1.2em; line-height: 1.45em;">
                      ${organizationFieldData?.calledservicesettingsorganizationfield?.label}:&nbsp;
                    </span>
                    <span class="MuiTypography-root MuiTypography-p" style="font-size: 1.2em; line-height: 1.45em;">
                      ${valueData}
                    </span>
                  </div>`
              }
            }
            oFieldsSuccessText += '</div>'

            let description = `<div>
            <p>Nome do solicitante: ${name}</p>
            <p>CPF do solicitante: ${document}</p>
            <p>E-mail do solicitante: ${email}</p>
            ${ oFieldsValuesText }
            <br/>
            <p>Eu, ${ name.length == 0 ? '(nome do cidadão)' : name }, inscrito no CPF, ${ document.length == 0 ? '(CPF do cidadão)' : document }, autorizo o Ministério da Gestão e da Inovação em Serviços Públicos a alterar meus dados cadastrais na plataforma de autenticação do GOV.BR.<br/>${ moment().format('DD/MM/YYYY') }</p>
            </div>`

            let data = new FormData()
            data.append('calledservicesettingsid', props?.calledServiceSettingsStep?.calledservicesettings?.calledservicesettingsid)
            data.append('route', "desativar-2fa-v2")
            data.append('username', name)
            data.append('useremail', email)
            data.append('userdocument', document)
            data.append('hirerid', props?.calledServiceSettingsStep?.calledservicesettings?.hirerid)
            //data.append('organizationfields', JSON.stringify(oFields))
            data.append('organizationid', props?.calledServiceSettingsStep?.calledservicesettings?.id)
            data.append('description', description)
            data.append('validateuserpeplist', true)
            data.append('validateuserblacklist', true)

            oFields.forEach((organizationFieldData, index) => {
              data.append(`organizationfields[${index}].id`, organizationFieldData.id);
              data.append(`organizationfields[${index}].value`, organizationFieldData.value);
            })

            let files = []
            for (const [key, value] of Object.entries(formValues)) {
              if (key.startsWith("files-")) {
                Mexx2DOConsole.log("File send forearch")
                Mexx2DOConsole.log(key)
                Mexx2DOConsole.log(value)
                let keyStringSplit = key.split('-')
                let filesList = value.map(fileItem => {
                  let finalFile = fileItem
                  if (fileItem && fileItem.side && fileItem.side.length > 0 && fileItem.src && fileItem.src.length > 0) {
                    let guidId = uuidv4()
                    let uuidNoHyphens = guidId.replace(/-/g, '');
                    let numericGuid = '';
                    for (let i = 0; i < uuidNoHyphens.length; i++) {
                        numericGuid += uuidNoHyphens.charCodeAt(i).toString();
                    }
                    finalFile = base64ToFile(fileItem.src, `${numericGuid}_document_${fileItem.side}`)
                  }

                  return finalFile
                })
                files.push({ id: keyStringSplit[1], files: [...filesList] })
              }
            }
            Mexx2DOConsole.log(files)

            let bigFile = []
            let invalidFormatFile = []
            let fileMaxSize = 1024 * 50 // 50 MB

            let validFiles = []
            files.forEach(async item => {
              let isInvalid = false
              let isBig = false
              let renamedFiles = item.files.map(fileItem => {
                if (isInvalidFileType(fileItem.type)) {
                  isInvalid = true
                  invalidFormatFile.push(fileItem.name)
                }
        
                let fileKbSize = Math.round((fileItem.size / 1024))
                if (fileKbSize > fileMaxSize) {
                  isBig = true
                  bigFile.push(fileItem.name)
                }
        
                let randomCode = randomIntFromInterval(100, 10000)
                let renamedFile = new File([fileItem], `${randomCode}_${fileItem.name}`)

                return renamedFile
              })
              if (!isInvalid && !isBig) {
                validFiles.push({ id: item.id, files: [...renamedFiles] })
              }
            });
            Mexx2DOConsole.log("=== validFiles ===")
            Mexx2DOConsole.log(validFiles)

            /*data.append('fileuploads', JSON.stringify(validFiles))*/

            validFiles.forEach((upload, index) => {
              data.append(`fileUploads[${index}].id`, upload.id);
              upload.files.forEach((file, fileIndex) => {
                data.append(`fileUploads[${index}].files`, file);
              });
            });

            data.append('faceLivenessBase64Image', referenceimage)

            if (bigFile.length == 0 && invalidFormatFile.length == 0) {
              let response = await createClientExternalCalled(data)
              if (response && response.success) {
                setLoading(false)
                showSnackbar('success', 'Solicitação criada com sucesso!')
                if (response.data != null) {
                  let reponseData = {
                    calledId: response.data.id,
                    createdAt: response.data.createdat,
                    name,
                    email,
                    document,
                    ofieldsText: oFieldsSuccessText,
                    backRoute: "desativar-2fa-v2"
                  }
                  props.setCreatedCalledData(reponseData)
                }
              } else {
                if (response && response.errors && response.errors[0]) {
                  let error = response.errors[0]
                  if (error === 'PEP_Error') {
                    setDocumentPep(formValues.document)
                    setPepExistsFormValidationError(true)
                  } else if (error === 'Blacklist_Error') {
                    setDocumentBlacklist(formValues.document)
                    setBlacklistExistsFormValidationError(true)
                  } else if (error === 'Called_Exists') {
                    const openingDate = moment(response.data.openingdate).format('DD/MM/YYYY HH:mm')
                    const calledId = response.data.calledid
                    if (response.errors.length > 1 && response.errors[1] == 'Email_Validation') {
                      setEmailExistsProtocol(calledId)
                      setEmailExistsOpeningDate(openingDate)
                      setDocumentExistsFormValidationError(true)
                    } else {
                      setDocumentExistsProtocol(calledId)
                      setDocumentExistsOpeningDate(openingDate)
                      setDocumentExistsFormValidationError(true)
                    }
                  } else if (error === 'Validation_Error') {
                    setDynamicFormValidationError(true)
                    if (response.data) {
                      setDynamicFormValidationErrorDescription(response.data)
                    }
                    return false
                  } else {
                    showSnackbar('error', 'Ocorreu um erro ao criar a solicitação!')
                    validated = false
                  }
                  validated = false
                } else {
                  showSnackbar('error', 'Ocorreu um erro ao criar a solicitação!')
                  validated = false
                }
                setLoading(false)
              }
            } else {
              let errorMessage = ''

              if (bigFile.length > 0)
                errorMessage = 'Você não pode enviar arquivos com o tamanho maior que 50MB'
              if (bigFile.length > 0 && invalidFormatFile.length > 0)
                errorMessage += '\n'
              if (invalidFormatFile.length > 0) {
                errorMessage += 'São aceitos somente arquivos no formato: \n'
                errorMessage += 'Imagens - BMP, GIF, JPG, JPEG, PNG e TIFF; \n'
                errorMessage += 'Documentos DOC, DOCX, ODP, ODS, ODT, PDF, PPT, PPTX e RTF; \n'
              }
              setLoading(false)
              showSnackbar('error', errorMessage)
              validated = false
            }

            return dataConfirmDeclaration && validated
          }
        }
      ]
    }
  ];

  const requestCameraPermission = async _ => {
    try {
      // Solicita permissão para a câmera frontal (facingMode: "user")
      const frontCameraStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'user' },
        audio: true
      });
      Mexx2DOConsole.log('Permissão concedida para a câmera frontal.');
      
      // Para o stream da câmera frontal após a permissão
      frontCameraStream.getTracks().forEach(track => track.stop());
  
      // Solicita permissão para a câmera traseira (facingMode: "environment")
      const backCameraStream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
        audio: true
      });
      Mexx2DOConsole.log('Permissão concedida para a câmera traseira.');
      
      // Para o stream da câmera traseira após a permissão
      backCameraStream.getTracks().forEach(track => track.stop());
  
    } catch (error) {
      console.error('Erro ao acessar as câmeras: ', error);
    }
  }

  const stopAllStreams = async _ => {
    Mexx2DOConsole.log('=== stopAllStreams ===');
    const stream = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'user' },
      audio: true
    });
    Mexx2DOConsole.log(stream)
    Mexx2DOConsole.log(stream.getTracks())
    stream.getTracks().forEach(track => track.stop()); // Para todos os streams ativos (câmera e microfone)
  }

  useEffect(() => {
    //requestCameraPermission()
    props.resetTriggerResetSteps()
    props.resetStepper()
    props.resetDataAlterEmailStepForMineConfirmEmail()
    props.resetDataDialogDocumentRecognitionStep()
    props.resetAttachmentForMinorStep()
    props.resetCalledServiceSettingsStepsData()
    props.resetCreatedCalledData()
    props.resetFaceRecognitionStep()
    if (Api === null || typeof Api === 'undefined') {
      setApiError(true)
    } else {
      getFirstOrganization()
    }
  }, [])

  useEffect(() => {
    if (props.trigger) {
      handleResetFormSteps()
    }
  }, [props.trigger]);

  useEffect(() => {
    if (alterEmailStepForMineIsCompleted && !interactionOccurred) {
      buttonCompletedRef?.current?.handleComplete()
      setInteractionOccurred(true)
    }
  }, [interactionOccurred, alterEmailStepForMineIsCompleted])

  useEffect(() => {
    if (documentRecognitionStepIsCompleted && !documentInteractionOccurred) {
      buttonCompletedRef?.current?.handleComplete()
      setDocumentInteractionOccurred(true)
    }
  }, [documentInteractionOccurred, documentRecognitionStepIsCompleted])

  return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh'
       }}>
        <CssBaseline />
        <Header />
        <Container maxWidth="xl" sx={{
          marginTop: '0',
          maxWidth: '100%!important',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column'
          }}>
          <IconBreadcrumbs actualPage="Desativar verificação em duas etapas" previousPage={{ text: "Desativar a verificação em duas etapas", link: "https://www.gov.br/governodigital/pt-br/acessibilidade-e-usuario/atendimento-gov.br/duvidas-na-conta-gov.br/desativar-a-verificacao-em-duas-etapas" }} />
          {(!startForm || isAllStepsCompleted || formValidationError) && <main className='main-content' style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', marginBottom: 'var(--spacing-scale-2x)' }}>
            <div>
                <h1 style={{ margin: 0, fontSize: '1.8em' }}>Formulário de atendimento</h1>
              <h2 style={{ margin: 0, marginBottom: '1em', fontWeight: '400', fontSize: '1.2em' }}>Desativar verificação em duas etapas</h2>
            </div>
            <div style={{ flexGrow: 1, display: 'flex', marginBottom: 'var(--spacing-scale-2x)' }}>
              <Card sx={{ flexGrow: 1 }}
                elevation={2}>
                <CardContent sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}>
                  <div style={{ flexGrow: 1 }}>
                    {(!isAllStepsCompleted && !formValidationError) && <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: !isMobile ? 'row' : 'column' }}>
                      {isMobile && <DuvidaIlustracaoImage className="doubt-illustration-image" />}
                      <div style={ !isMobile ? isDesktop ? { minWidth: '60%' } : { minWidth: '75%' } : { minWidth: '100%' }}>
                        <p style={{ maxWidth: '80%' }}>Antes de desativar a verificação em duas etapas, precisamos confirmar a sua identidade. É uma verificação importante para evitar fraudes e garantir a segurança da sua conta. Você vai precisar enviar imagens do seu rosto e documento. <strong>Por essa razão, será mais fácil realizar todo o processo utilizando um celular</strong>.</p>
                        <p style={{ maxWidth: '90%' }}>Ao final do processo, o nível da sua conta será ajustado para bronze por questões de segurança.</p>
                      </div>
                      {!isMobile && <div style={{
                        marginTop: 'var(--spacing-scale-2x)',
                        marginRight: 'var(--spacing-scale-2x)',
                        display: 'flex',
                        justifyContent: 'flex-end'
                      }}>
                        <DuvidaIlustracaoImage className="doubt-illustration-image" />
                      </div>}
                    </div>}
                    {(isAllStepsCompleted && !formValidationError) && <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <CheckCircleIcon />
                        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Solicitação enviada com sucesso!</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <TagIcon />
                        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Protocolo:</Typography>
                        <Typography variant="body1" component="span" >&nbsp;&nbsp;{props.createdCalled.calledId}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <CalendarMonthIcon />
                        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Data de abertura:</Typography>
                        <Typography variant="body1" component="span" >&nbsp;&nbsp;{moment(props.createdCalled.createdAt).format('DD/MM/YYYY HH:mm:ss')}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" component="span" >Você receberá uma resposta sobre a solicitação em sua caixa de e-mail em até 1 dia útil após a solicitação.</Typography>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="body1" component="span" >Se não encontrar, lembre-se de verificar também na sua pasta de lixo eletrônico ou spam.</Typography>
                      </Grid>
                    </Grid>}
                    {documentExistsFormValidationError && <OpenedCalled
                      protocol={documentExistsProtocol !== '0000000' && documentExistsProtocol.length > 0 ? documentExistsProtocol : emailExistsProtocol}
                      openingDate={documentExistsProtocol !== '0000000' && documentExistsProtocol.length > 0 ? documentExistsOpeningDate : emailExistsOpeningDate} />}
                    {pepFormValidationError && <PEP document={documentPep} />}
                    {blacklistFormValidationError && <Blacklist document={documentBlacklist} />}
                    {dynamicFormValidationError && <Validation description={dynamicValidationErrorDescription} />}
                  </div>
                  <div>
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      justifyContent={ (isAllStepsCompleted || formValidationError) ? "flex-end" : "space-between" }
                      alignContent={isMobile ? "center" : "normal"}
                      flexWrap={isMobile ? "wrap" : "nowrap"}
                      gap={2}
                      sx={{
                        position: 'relative',
                        bottom: 0,
                        left: 0,
                        right: 0
                      }}
                    >
                      <Box
                        sx={{
                          flexShrink: 1,
                          alignSelf: { xs: 'flex-end', sm: 'flex-end', md: 'center' },
                        }}
                        className="gv-br-button-init-form-box"
                      >
                        {(isAllStepsCompleted && !formValidationError) && <GVButton
                          size="small"
                          variant="outlined"
                          label="? Uso dos meus dados"
                          color="info"
                          onClick={onOpenMyDataDialog}
                          sx={{
                            textTransform: 'none!important'
                          }}
                          fullWidth={isMobile}
                          className="gv-br-button-init-form-item" />}
                        {(!isAllStepsCompleted && !formValidationError) && <GVButton
                          onClick={() => {window.location.href = 'https://acesso.gov.br/'}}
                          size="small"
                          label="Voltar ao login gov.br"
                          style={{ textTransform: 'none' }}
                          fullWidth={isMobile}
                          className="gv-br-button-init-form-item" />}
                        {(formValidationChatError) && <GVButton
                          onClick={() => {window.location.href = 'https://www.gov.br/governodigital/pt-br/acessibilidade-e-usuario/atendimento-gov.br'}}
                          variant="contained"
                          label="Conversar com o chat"
                          style={{ textTransform: 'none' }}
                          fullWidth={isMobile}
                          className="gv-br-button-init-form-item" />}
                      </Box>
                      {(!isAllStepsCompleted && !formValidationError) && <Stack
                        gap={2}
                        direction={{
                          sm: 'column',
                          md: 'row'
                        }}
                        sx={{
                          flexShrink: 0,
                          alignSelf: { xs: 'flex-end', sm: 'center', md: 'center' }
                        }}
                        className="gv-br-button-init-form-stack"
                      >
                        <GVButton
                        size="small"
                        variant="outlined"
                        label="Não estou no celular"
                        color="info"
                        onClick={openNotOnTheCellphoneDialog}
                        sx={{
                          textTransform: 'none!important'
                        }}
                        fullWidth={isMobile}
                        className="gv-br-button-init-form-item" />
                        <GVButton size="small"  variant="contained"
                        label="Iniciar"
                        color="success"
                        className="gv-br-button-init-form-item gv-br-button-start"
                        onClick={handleStart}
                        fullWidth={isMobile} />
                      </Stack>}
                    </Stack>
                  </div>
                </CardContent>
              </Card>
            </div>
          </main>}
          {(startForm && !isAllStepsCompleted && !formValidationError) && <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)} style={{ flexGrow: 3, display: 'flex' }}>
              <GVStepper formSubmit={methods.handleSubmit} steps={steps} CompletedStep={StepCompleted} handleBack={handleBackToStart} completedRef={buttonCompletedRef} resetStepper={resetFormSteps}/>
              <GvDialog
                open={documentRecognitionDialogOpen}
                onClose={closeDocumentRecognitionDialog}
                title={`Leitura do documento do ${(legalResponsible) ? "responsável legal" : legalRepresentant ? "representante legal" : "titular"}`}
                fullWidth={true}
                fullScreen={true}
                maxWidth={'xl'}
                withFooter
                customPadding='10px 0 0 0!important'
                content={<Grid container spacing={2}>
                  {documentRecognitionDialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body1" component="span" >Siga as instruções abaixo para realizar a leitura do documento:</Typography>
                  </Grid>}
                  {documentRecognitionDialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconSun />
                    <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Esteja em uma área bem iluminada que não esteja exposta à luz solar direta</Typography>
                  </Grid>}
                  {documentRecognitionDialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconCaptureDocument />
                    <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Posicione o documento dentro ad marcação, sem cortar nenhuma parte dele.</Typography>
                  </Grid>}
                  {documentRecognitionDialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconDocumentSwitch />
                    <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cada lado do documento será fotografado separadamente, primeiro a frente, depois o verso</Typography>
                  </Grid>}
                  {documentRecognitionDialogStep == 2 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <DocumentRecognitionDialog
                      onClose={closeDocumentRecognitionDialog}
                      onConfirm={confirmDocumentRecognition} />
                  </Grid>}
                </Grid>}
                actions={
                <>
                  {documentRecognitionDialogStep == 1 &&<GVButton
                    variant="outlined"
                    color="inherit"
                    onClick={closeDocumentRecognitionDialog}
                    sx={{ mr: 1 }}
                    style={isMobile ? { marginLeft: 8, marginRight: 8, marginBottom: 8 } : {}}
                    label="Cancelar" />}
                  {documentRecognitionDialogStep == 1 &&<GVButton 
                    onClick={startDocumentRecognition}
                    variant="contained"
                    label="Iniciar"
                    style={isMobile ? { marginLeft: 8, marginRight: 8 } : {}} />}
                </>
                }
                name={'document-recognition-dialog'} />
            </form>
          </FormProvider>}
        </Container>
        <CookiesBanner />
        <DocumentExistsValidationDialog
          open={documentExistsDialogOpen}
          onClose={onCloseDocumentExistsDialog}
          protocol={documentExistsProtocol}
          openingDate={documentExistsOpeningDate} />
        <EmailExistsValidationDialog
          open={emailExistsDialogOpen}
          onClose={onCloseEmailExistsDialog}
          protocol={emailExistsProtocol}
          openingDate={emailExistsOpeningDate} />
        <DocumentPepValidationDialog
          open={documentPepDialogOpen}
          onClose={onCloseDocumentPepDialog}
          document={documentPep} />
        <DocumentBlacklistValidationDialog
          open={documentBlacklistDialogOpen}
          onClose={onCloseDocumentBlacklistDialog}
          document={documentBlacklist} />
        <GvDialog
          open={notOnTheCellphoneDialogOpen}
          onClose={closeNotOnTheCellphoneDialog}
          title={""}
          content={<Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="body1" component="span" >Se neste momento você não está no celular, utilize a câmera do seu telefone para ler o QR Code abaixo e continuar o processo em seu celular.</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <QRCode
                size={256}
                style={isMobile ? { height: "auto", maxWidth: "50%", width: "50%" } : { height: "auto", maxWidth: "25%", width: "25%" }}
                value={currentURL}
                viewBox={`0 0 138 256`} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="body1" component="span" >Se não tiver um celular disponível, ainda será possível voltar e iniciar o processo em um computador com câmera (a qualidade da imagem poderá ser inferior).</Typography>
            </Grid>
          </Grid>}
          actions={
            <GVButton 
              onClick={closeNotOnTheCellphoneDialog}
              variant="outlined"
              label="Voltar"
              style={isMobile ? { marginLeft: 8, marginRight: 8 } : {}} />
            }
          fullWidth={true}
          maxWidth={'md'}
          name={'not-on-the-cellphone-dialog'} />
        <GvDialog
          open={faceRecognitionDialogOpen}
          onClose={closeFaceRecognitionDialog}
          title={`Leitura do rosto do ${(legalResponsible) ? "responsável legal" : legalRepresentant ? "representante legal" : "titular"}`}
          fullWidth={true}
          fullScreen={true}
          maxWidth={'xl'}
          withFooter
          customPadding='10px 0 0 0!important'
          content={<Grid container spacing={2}>
            {faceRecognitionDialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography variant="body1" component="span" >Siga as instruções abaixo para realizar a leitura do rosto:</Typography>
            </Grid>}
            {faceRecognitionDialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconSun />
              <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Esteja em uma área bem iluminada que não esteja exposta à luz solar direta</Typography>
            </Grid>}
            {faceRecognitionDialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconNoGlasses />
              <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Não cubra o rosto com óculos de sol, máscara ou demais acessórios</Typography>
            </Grid>}
            {faceRecognitionDialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconFaceFrame />
              <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Mantenha o rosto dentro da moldura oval durante todo o processo</Typography>
            </Grid>}
            {faceRecognitionDialogStep == 1 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', alignItems: 'center' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<IconSunBright />
              <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Apenas movimente o rosto se solicitado</Typography>
            </Grid>}
            {faceRecognitionDialogStep == 2 && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <article className="liveness-face" >
                <LivenessFaceClass
                  handleRestartRef={livenessClassRef}
                  getFaceLivenessSessionResults={getFaceLivenessSessionResults}
                  handleErrorFaceLivenessSession={handleErrorFaceLivenessSession}
                  showSnackbar={showSnackbar}
                  changeButtonLabel={changeButtonLabel} />
              </article>
            </Grid>}
          </Grid>}
          actions={
          <>
            {(faceRecognitionStepHasAttemps != null && faceRecognitionStepHasAttemps >= 5) && <GVButton
              onClick={() => {window.open('https://www.gov.br/governodigital/pt-br/acessibilidade-e-usuario/atendimento-gov.br')}}
              size="small"
              label="Falar com o chat"
              style={isMobile ? { marginLeft: 8, marginRight: 8, marginBottom: 8, textTransform: 'none' } : { textTransform: 'none' }}
              fullWidth={isMobile}
              className="gv-br-button-init-form-item" />}
            <GVButton
              variant="outlined"
              color="inherit"
              onClick={closeFaceRecognitionDialog}
              sx={{ mr: 1 }}
              style={isMobile ? { marginLeft: 8, marginRight: 8, marginBottom: 8 } : {}}
              label="Cancelar" />
            {faceRecognitionDialogStep == 1 && <GVButton 
              onClick={startFaceRecognition}
              variant="contained"
              label="Iniciar"
              style={isMobile ? { marginLeft: 8, marginRight: 8, marginBottom: 8 } : {}} />}
            {faceRecognitionStepHasError && <GVButton 
              onClick={() => {
                props.setFaceRecognitionStepError(false)
                Mexx2DOConsole.log("=== setFaceRecognitionStepError ===")
                Mexx2DOConsole.log(livenessClassRef)
                livenessClassRef?.current?.handleExternalRestartSession()
              }}
              variant="contained"
              label="Tentar novamente"
              style={isMobile ? { marginLeft: 8, marginRight: 8 } : {}} />}
          </>
          }
          name={'face-recognition-dialog'} />
        <GvDialog
          open={myDataDialogOpen}
          onClose={onCloseMyDataDialog}
          title={"Uso dos meus dados"}
          content={<GVMyDataDialogContent />}
          actions={
            <GVButton 
              onClick={onCloseMyDataDialog}
              variant="contained"
              label="Voltar"
              style={isMobile ? { marginLeft: 8, marginRight: 8 } : {}} />
            }
          fullWidth={true}
          maxWidth={'md'}
          name={'personal-data-email-confirmation'} />
        <Footer/>
        {loading && (
          <Overlay>
            <CircularProgress color="secondary" />
          </Overlay>
        )}
        <CustomizedSnackbars
          variant={notificationVariant}
          message={notificationMessage}
          isOpen={openNotification}
          toClose={closeNotification} />
      </div>
  );
};

const mapStateToProps = state => ({
  alterEmailStepForMine: state.alterEmailStepForMine,
  calledServiceSettingsStep: state.calledServiceSettingsStep,
  documentRecognitionStep: state.documentRecognitionStep,
  faceRecognitionStep: state.faceRecognitionStep,
  stepper: state.stepper,
  trigger: state.triggers.trigger,
  createdCalled: state.createdCalled
})

const mapDispatchToProps =
  dispatch => bindActionCreators(Object.assign({}, componentActions, createdCalledActions, stepperComponentActions, documentRecognitionStepComponentActions, faceRecognitionStepComponentActions, attachmentForMinorStepComponentActions, stepComponentActions, triggersComponentActions), dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Disable2FactorVerification)