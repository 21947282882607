export function setCalledServiceSettingsStepsData(data) {
  return {
      type: 'SET_CALLED_SERVICE_SETTINGS_STEPS_DATA',
      calledservicesettings: data.calledservicesettings,
      steps: data.steps,
  }
}

export function resetCalledServiceSettingsStepsData() {
  return {
      type: 'RESET_CALLED_SERVICE_SETTINGS_STEPS_DATA'
  }
}