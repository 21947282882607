import moment from "moment";

const initialState = {
  calledId: 0,
  createdAt: moment(),
  name: "",
  email: "",
  document: "",
  ofieldsText: "",
  backRoute: ""
}

export default function createdCalled(state = initialState, action) {
  switch (action.type) {
    case 'SET_CREATED_CALLED_DATA':
      return {
        ...state,
        calledId: action.calledId,
        createdAt: action.createdAt,
        name: action.name,
        email: action.email,
        document: action.document,
        ofieldsText: action.ofieldsText,
        backRoute: action.backRoute
      }
    case 'RESET_CREATED_CALLED_DATA':
      return initialState
    default:
      return state;
  }
}