export function setCompletedStepper(activeStep) {
  return {
    type: 'SET_COMPLETED',
    activeStep
  }
}

export function setCompleteAllSteps() {
  return {
    type: 'SET_COMPLETED_ALL_STEPS'
  }
}

export function setTotalSteps(totalSteps) {
  return {
    type: 'SET_TOTAL_STEPS',
    totalSteps
  }
}

export function resetStepper() {
  return {
    type: 'RESET_STEPPER'
  }
}