import * as React from 'react';
import Dialog, { dialogClasses } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/styles';
import { Typography } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import theme from '../../../styles/theme'
import Footer from '../footer';

const GVBRDialog = styled(Dialog)(({ theme, custompadding }) => ({
  [`& .${dialogClasses.paper}`]: {
    padding: custompadding || '10px 25px 25px 25px'
  }
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GvDialog({ open, onClose, title, content, actions, fullWidth, maxWidth, name, TitleIcon, fullScreen = false, withFooter = false, style = {}, customPadding = undefined, dialogContentClearPadding = false }) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <GVBRDialog
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        maxWidth={maxWidth}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        style={style}
        aria-describedby={`${name}-description`}
        custompadding={customPadding}
      >
        {title && <DialogTitle style={{ marginTop: 0, marginBottom: 0, textTransform: 'none', display: TitleIcon != null ? 'flex' : 'inline' }}>
          {TitleIcon && <TitleIcon/>}
          <Typography variant="body1" fontWeight="bold" style={{ textTransform: 'none' }} >{title}</Typography>
        </DialogTitle>}
        <DialogContent style={dialogContentClearPadding ? { padding: 0 } : {}}>
          {typeof content === 'string' ? <DialogContentText>{content}</DialogContentText> : content}
        </DialogContent>
        <DialogActions style={ isMobile ? { flexDirection: 'column' } : {}}>
          {actions}
        </DialogActions>
        {withFooter && <Footer/>}
    </GVBRDialog>
  );
}