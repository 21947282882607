export function setFaceRecognitionStepError(hasError) {
  return {
      type: 'SET_FACE_RECOGNITION_STEP_ERROR',
      hasError,
  }
}

export function addFaceRecognitionStepLimitAttemp() {
  return {
      type: 'FACE_RECOGNITION_STEP_ADD_LIMIT_ATTEMP'
  }
}

export function resetFaceRecognitionStepLimitAttemp() {
  return {
      type: 'FACE_RECOGNITION_STEP_RESET_LIMIT_ATTEMP'
  }
}

export function resetFaceRecognitionStep() {
  return {
      type: 'RESET_FACE_RECOGNITION_STEP_ERROR'
  }
}