export function setTriggerResetSteps() {
  return {
    type: 'SET_TRIGGER_RESET_STEPS'
  }
}

export function resetTriggerResetSteps() {
  return {
    type: 'RESET_TRIGGER_RESET_STEPS'
  }
}