import React from 'react'
import { useFormContext } from 'react-hook-form'
import GVRadioButton from '../../../../components/gov-br/radio'
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import { connect, useSelector } from 'react-redux';
import CompletedFormReadData from '../completed-form-read-data';
import { Grid } from '@mui/material';
import GVFeedback from '../../../../components/feedback';
import GVMessage from '../../../../components/gov-br/message';
import GVAutocomplete from '../../../../components/gov-br/autocomplete';

const StepGeneralInfo = React.memo(function StepGeneralInfo() {
  const methods = useFormContext()
  const formValues = methods.watch()

  const completed = useSelector((state) => state.stepper.completed)
  const isCompleted = completed?.[0] === true;

  const stepsData = useSelector((state) => state.calledServiceSettingsStep.steps)
  const stepData = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 1).sort((a, b) => a.order - b.order) : []

  const formatOptions = (options, orderType = "id") => {
    if (options && options.length > 0)
      options.sort((a, b) => a[orderType] - b[orderType])
    else
      options = []
    return options
  }

  if (isCompleted) {
    return (<Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <GVMessage success>Esta etapa já foi concluída. Caso deseje alterar os dados, volte ao início</GVMessage>
      </Grid>
      {stepData.map(element => {
        if (element.fieldtype == 2) {
          let name = ""
          const label = element.calledservicesettingsorganizationfield?.label
          const options = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
          const fieldId = element.calledservicesettingsorganizationfield?.id
          const organizationfieldtype = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype
          const Icon = label === "Qual o motivo da solicitação?" ? LiveHelpIcon : label === "Qual o serviço sendo acessado?" ? MeetingRoomIcon : Diversity3Icon
          if (organizationfieldtype === 2) {
            const selected = options?.find(option => option.id == formValues[`ofield-${fieldId}`])
            name = selected ? selected.name : "Opção não encontrada"
          } else if (organizationfieldtype === 4) {
            name = formValues[`ofield-${fieldId}`].name
          } else {
            name = formValues[`ofield-${fieldId}`]
          }
          return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <CompletedFormReadData
              label={label}
              StartIcon={Icon}
              data={`${name}`} />
          </Grid>)
        }
      })}
    </Grid>)
  } else {
    return (<Grid container spacing={2}>
      {stepData.map(element => {
        const label = element.calledservicesettingsorganizationfield?.label
        const options = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
        const fieldId = element.calledservicesettingsorganizationfield?.id
        const organizationfieldtype = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype
        if (element.fieldtype == 2) {
          if (organizationfieldtype === 2) {
            return (<Grid key={element.id} item xs={12} sm={12} md={12} lg={12} xl={12}>
              <GVRadioButton
                StartIcon={label == "Qual o motivo da solicitação?" ? LiveHelpIcon : Diversity3Icon}
                name={`ofield-${fieldId}`}
                label={label}
                isRequired={true}
                options={formatOptions(options)} />
            </Grid>)
          } else if (organizationfieldtype === 4) {
            return (<Grid key={element.id} item xs={12} sm={12} md={12} lg={6} xl={6}>
              <GVAutocomplete
                name={`ofield-${fieldId}`}
                StartIcon={MeetingRoomIcon}
                label={label}
                options={formatOptions(options, "name")}
                fullWidth
                required />
            </Grid>)
          }
        }
      })}
    </Grid>)
  }
})

export default StepGeneralInfo;