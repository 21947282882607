import React from 'react'
import GvDialog from '../dialog';
import { Grid, Typography } from '@mui/material';
import GVButton from '../button';
import TagIcon from '@mui/icons-material/Tag';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const DocumentExistsValidationDialog = ({ open, onClose, protocol, openingDate }) => {

  return (<GvDialog
    open={open}
    onClose={onClose}
    title={"Este CPF já possui uma solicitação registrada e em andamento"}
    content={<Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <TagIcon />
          <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Protocolo:</Typography>
          <Typography variant="body1" component="span" >&nbsp;&nbsp;{protocol}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CalendarMonthIcon />
          <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Data de abertura:</Typography>
          <Typography variant="body1" component="span" >&nbsp;&nbsp;{openingDate}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Aguarde o atendimento desta solicitação em até 3 dias.</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Sempre verifique se a notificação do resultado chegou em sua caixa de e-mail ou lixo eletrônico (Spam).</Typography>
        </Grid>
      </Grid>}
    actions={
      <>
        <GVButton 
          onClick={onClose}
          variant="contained"
          label="Voltar" />
      </>
    }
    fullWidth={true}
    maxWidth={'md'}
    name={'document-exists-validation-dialog'} />)
}

export default DocumentExistsValidationDialog;