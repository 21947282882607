import * as React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import typography from '../../../styles/typography'
import Checkbox from '@mui/material/Checkbox';
import GVFeedback from '../../feedback';

const GVBRCheckbox = styled(Checkbox)(({ theme }) => ({
  
}))

const GVBRFormLabel = styled(FormLabel)(({ theme }) => ({
  ...typography.label,
  marginLeft: '-10px',
  marginBottom: '0!important'
}))

function GVCheckbox({ name, label, options, isRequired  }) {
  const { control, formState: { errors }, clearErrors } = useFormContext();

  const validateAtLeastOne = (value) => {
    const isAnyChecked = options.some(option => value[option.id]);
    return isAnyChecked || 'Pelo menos um checkbox deve ser marcado';
  };

  return (<React.Fragment><Controller
    name={name}
    control={control}
    defaultValue={options.reduce((acc, option) => {
      acc[option.id] = false;
      return acc;
    }, {})}
    rules={isRequired ? { validate: validateAtLeastOne } : {}}
    render={({ field: { onChange, value = {} } }) => (
      <FormControl
        required={isRequired}
        error={Boolean(errors && errors[name])}
        component="fieldset"
        variant="standard"
      >
        <GVBRFormLabel component="legend">{label}</GVBRFormLabel>
        <FormGroup>
          {options.map(option => (
            <FormControlLabel
              key={option.id}
              control={
                <GVBRCheckbox
                  checked={value[option.id] || false}
                  onChange={(e) => {
                    const newValue = {
                      ...value,
                      [option.id]: e.target.checked,
                    };
                    onChange(newValue);
                    if (e.target.checked)
                      clearErrors(name)
                  }}
                />
              }
              label={option.label}
            />
          ))}
        </FormGroup>
        {isRequired && errors && errors[name] && (
          <GVFeedback danger>{errors[name].message}</GVFeedback>
        )}
      </FormControl>
    )}
  />
  </React.Fragment>);
}

GVCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      label: PropTypes.object.isRequired,
    })
  ).isRequired,
  isRequired: PropTypes.bool
};

export default GVCheckbox;