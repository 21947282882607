import React from 'react'
import GvDialog from '../dialog';
import { Grid, Typography } from '@mui/material';
import GVButton from '../button';
import TagIcon from '@mui/icons-material/Tag';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

const DocumentPepValidationDialog = ({ open, onClose, document }) => {

  const openChatGovBr = (e) => {
    e.preventDefault()
    window.open("https://www.gov.br/governodigital/pt-br/acessibilidade-e-usuario/atendimento-gov.br", "_blank")
    onClose()
  }

  return (<GvDialog
    open={open}
    onClose={onClose}
    title={"Este CPF faz parte do grupo de Pessoas Expostas Politicamente (PEP)"}
    content={<Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <AssignmentIndIcon />
          <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >{document}</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Por questões de segurança, não será possível solicitar a alteração do e-mail cadastrado nesta conta gov.br através do Formulário de Atendimento.</Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="body1" component="span" >Acesse nosso chat e identifique-se como Pessoa Exposta Politicamente para prosseguir com seu atendimento.</Typography>
        </Grid>
      </Grid>}
    actions={
      <>
        <GVButton
          variant="outlined"
          color="inherit"
          sx={{ mr: 1 }}
          onClick={onClose}
          label="Voltar" />
        <GVButton 
          onClick={openChatGovBr}
          variant="contained"
          label="Chat gov.br" />
      </>
    }
    fullWidth={true}
    maxWidth={'md'}
    name={'document-exists-validation-dialog'} />)
}

export default DocumentPepValidationDialog;