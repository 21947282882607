import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as componentActions from '../../../../../../redux/actions/document-recognition-step'
import { Grid } from '@mui/material'
import MultiStepCamera from '../../../../../../components/gov-br/multi-step-camera'
import { FormProvider, useForm } from 'react-hook-form'
import { Mexx2DOConsole } from '../../../../../../utils/mexxTalkConsole'

function DocumentRecognitionDialog({ calledServiceSettingsStep, documentRecognitionStep, onClose, onConfirm }) {

  const [uploadField, setUploadField] = useState(null)
  const [uploadNIFField, setUploadNIFField] = useState(null)

  const stepsData = useSelector((state) => state.calledServiceSettingsStep.steps)
  const documentRecognitionNIFStep = useSelector(state => state.documentRecognitionStep.documentNifStep)
  const stepData = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 5).sort((a, b) => a.order - b.order) : []

  useEffect(() => {
    if (stepData.length > 0) {
      let uField = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.filelimit == 2 && element?.calledservicesettingsfileupload.label == "Documento - Reconhecimento")
      setUploadField(uField)
      let uNIFField = stepData.find(element => element.fieldtype == 3 && element?.calledservicesettingsfileupload != null && element?.calledservicesettingsfileupload.filelimit == 2 && element?.calledservicesettingsfileupload.label == "Documento - Reconhecimento NIF")
      setUploadNIFField(uNIFField)
    }
  }, [])

  Mexx2DOConsole.log(documentRecognitionNIFStep)

  return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
    {documentRecognitionNIFStep === 1 && uploadField != null && <MultiStepCamera
        fieldName={`files-${uploadField?.calledservicesettingsfileupload?.id}`}
        onClose={onClose}
        onConfirm={onConfirm} />}
    {documentRecognitionNIFStep === 2 && uploadNIFField != null && <MultiStepCamera
        fieldName={`files-${uploadNIFField?.calledservicesettingsfileupload?.id}`}
        onClose={onClose}
        onConfirm={onConfirm} />}
  </Grid>)
}

const mapStateToProps = state => ({
  documentRecognitionStep: state.documentRecognitionStep,
  calledServiceSettingsStep: state.calledServiceSettingsStep
})

const mapDispatchToProps =
  dispatch => bindActionCreators(componentActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(DocumentRecognitionDialog)