import { combineReducers } from 'redux';
import userSession from './user-session';
import users from './users';
import forms from './form';
import alterEmailStepForMine from './alter-email-step-for-mine';
import stepper from './stepper';
import documentRecognitionStep from './document-recognition-step';
import calledServiceSettingsStep from './called-service-settings-step';
import createdCalled from './created-called';
import triggers from './triggers';
import attachmentForMinorStep from './attachment-for-minor-step';
import faceRecognitionStep from './face-recognition-step';

export default combineReducers({
    attachmentForMinorStep,
    calledServiceSettingsStep,
    faceRecognitionStep,
    userSession,
    users,
    forms,
    alterEmailStepForMine,
    stepper,
    documentRecognitionStep,
    createdCalled,
    triggers
});
