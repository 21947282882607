import Api from './api';

export const createClientExternalCalled = async (formData) => { 
  let data = []

  let config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }

  await Api.post(`/called/client/external`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}

export const validateExternalCalled = async (formData) => { 
  let data = []

  let config = {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  }

  await Api.post(`/called/client/external/validate`, formData, config)
    .then(result => {
      data = result.data
    })
    .catch(err => {
      data = err
    })
  return data
}