import React from 'react'
import { Grid, Typography } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import DocumentoDecisao from '../../../../../../components/images/documento-decisao';
import DocumentoProcurarao from '../../../../../../components/images/documento-procuracao';
import DocumentoCuratela from '../../../../../../components/images/documento-curatela';

const DocumentTypeInfo = React.memo(function DocumentTypeInfo({ inputName }) {
  const methods = useFormContext();
  const formValues = methods.watch()

  if (formValues[inputName] && formValues[inputName].name === 'Procuração pública ou privada') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <DocumentoProcurarao />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Typography variant="body1" component="span" >A procuração deve conceder poderes ao representante legal para representar o titular junta à plataforma gov.br, com nome completo e número de CPF. Além disso, também deverá possuir uam forma de comprovação oficial de autenticidade.</Typography>
      </Grid>
    </Grid>)
  } else if (formValues[inputName] && formValues[inputName].name === 'Termo judicial de curatela') {
    return (<Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <DocumentoCuratela />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Typography variant="body1" component="span" fontWeight="italic" >Imagem meramente ilustrativa</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
        <Typography variant="body1" component="span" >O termo judicial de curatela deve apresentar o nome e o CPF danto do titular quanto do representante legal. Além disso, o documento também deverá possuir uma forma de comprovação oficial de autenticidade pelo órgão emissor.</Typography>
      </Grid>
    </Grid>)
  } else {
    return (<React.Fragment></React.Fragment>)
  }
})

export default DocumentTypeInfo;