import React, { useEffect } from 'react'
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import * as componentActions from '../../../../redux/actions/alter-email-step-for-mine'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import PortraitIcon from '@mui/icons-material/Portrait';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import PersonIcon from '@mui/icons-material/Person';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MailIcon from '@mui/icons-material/Mail';
import { useFormContext } from 'react-hook-form';
import GVTooltip from '../../../../components/tooltip';
import InfoIcon from '@mui/icons-material/Info';
import GVCheckbox from '../../../../components/gov-br/checkbox';
import GVButton from '../../../../components/gov-br/button';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import GvDialog from '../../../../components/gov-br/dialog';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import GVMyDataDialogContent from '../../../../components/gov-br/dialog/my-data-dialog-content';

const StepSend = React.memo(function StepSend(props) {
  const [legalResponsible, setLegalResponsible] = React.useState(false)
  const [legalRepresentant, setLegalRepresentant] = React.useState(false)
  const [attachmentList, setAttachmentList] = React.useState([])
  const [dialogOpen, setDialogOpen] = React.useState(false)

  const stepsData = useSelector((state) => state.calledServiceSettingsStep.steps)
  const stepGeneralData = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 1).sort((a, b) => a.order - b.order) : []
  const stepForMinor = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 3).sort((a, b) => a.order - b.order) : []
  const stepForOther = stepsData && stepsData.length > 0 ? stepsData.filter(element => element.step === 4).sort((a, b) => a.order - b.order) : []

  const onOpenDialog = () => {
    setDialogOpen(true)
  }

  const onCloseDialog = () => {
    setDialogOpen(false)
  }

  const methods = useFormContext()
  const formValues = methods.watch()

  useEffect(() => {
    let fileList = []
    for (var key in formValues) {
      if (key.startsWith("files-")) {
        if (Array.isArray(formValues[key])) {
          formValues[key].forEach(item => {
            fileList.push(item)
          })
        } else
        fileList.push(formValues[key])
      }
    }
    let issetLegalReponsible = stepForMinor.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do responsável legal")
    if (issetLegalReponsible) {
      const fieldId = issetLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].length > 0)
        setLegalResponsible(true)
    }
    let issetOtherLegalReponsible = stepForOther.find(element => element.calledservicesettingsorganizationfield?.label === "Nome do representante legal")
    if (issetOtherLegalReponsible) {
      const fieldId = issetOtherLegalReponsible.calledservicesettingsorganizationfield?.id
      if (formValues[`ofield-${fieldId}`] && formValues[`ofield-${fieldId}`].length > 0)
        setLegalRepresentant(true)
    }
    setAttachmentList(fileList)
  }, [])

  return (<React.Fragment>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" fontWeight="bold" style={{ fontSize: 'var(--font-size-scale-up-02)' }} >Revise a solicitação antes de enviar</Typography>
      </Grid>
      {stepGeneralData.map(element => {
        if (element.fieldtype == 2) {
          let name = ""
          let label = element.calledservicesettingsorganizationfield?.label
          const options = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
          const fieldId = element.calledservicesettingsorganizationfield?.id
          const organizationfieldtype = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype
          const Icon = label === "Qual o motivo da solicitação?" ? LiveHelpIcon : label === "Qual o serviço sendo acessado?" ? MeetingRoomIcon : Diversity3Icon
          if (label === "Qual o motivo da solicitação?")
            label = "Motivo"
          else if (label === "Qual o serviço sendo acessado?")
            label = "Serviço"
          else if (label === "Para quem é esta solicitação?")
            label = "Para quem"

          if (organizationfieldtype === 2) {
            const selected = options?.find(option => option.id == formValues[`ofield-${fieldId}`])
            name = selected ? selected.name : "Opção não encontrada"
          } else if (organizationfieldtype === 4) {
            name = formValues[`ofield-${fieldId}`].name
          } else {
            name = formValues[`ofield-${fieldId}`]
          }
          
          return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Icon />
            <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >{label}:</Typography>
            <Typography variant="body1" component="span" >&nbsp;&nbsp;{name}</Typography>
          </Grid>)
        }
      })}
      {stepForMinor.map(element => {
        let name = ""
        const label = element.calledservicesettingsorganizationfield?.label
        const options = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
        const fieldId = element.calledservicesettingsorganizationfield?.id
        const organizationfieldtype = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype
        const Icon = label === "Nome do responsável legal" ? PersonIcon : AssignmentIndIcon
        if (organizationfieldtype === 2) {
          const selected = options?.find(option => option.id == formValues[`ofield-${fieldId}`])
          name = selected ? selected.name : "Opção não encontrada"
        } else if (organizationfieldtype === 4) {
          name = formValues[`ofield-${fieldId}`].name
        } else {
          name = formValues[`ofield-${fieldId}`]
        }
        if (legalResponsible) {
          return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Icon />
            <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >{label}:</Typography>
            <Typography variant="body1" component="span" >&nbsp;&nbsp;{name}</Typography>
          </Grid>)
        }
      })}
      {stepForOther.map(element => {
        let name = ""
        const label = element.calledservicesettingsorganizationfield?.label
        const options = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldoptionsvalues
        const fieldId = element.calledservicesettingsorganizationfield?.id
        const organizationfieldtype = element.calledservicesettingsorganizationfield?.organizationfield?.organizationfieldtype
        const Icon = label === "Nome do representante legal" ? PersonIcon : AssignmentIndIcon
        if (organizationfieldtype === 2) {
          const selected = options?.find(option => option.id == formValues[`ofield-${fieldId}`])
          name = selected ? selected.name : "Opção não encontrada"
        } else if (organizationfieldtype === 4) {
          name = formValues[`ofield-${fieldId}`].name
        } else {
          name = formValues[`ofield-${fieldId}`]
        }
        if (legalRepresentant) {
          return (<Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Icon />
            <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >{label}:</Typography>
            <Typography variant="body1" component="span" >&nbsp;&nbsp;{name}</Typography>
          </Grid>)
        }
      })}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <PersonIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Nome{(legalResponsible || legalRepresentant) && " do titular"}:</Typography>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;{formValues.name}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <AssignmentIndIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >CPF{(legalResponsible || legalRepresentant) && " do titular"}:</Typography>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;{formValues.document}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <MailIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >E-mail a ser cadastrado:</Typography>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;{formValues.email}</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <PortraitIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Leitura do rosto do {(legalResponsible) ? "responsável legal" : legalRepresentant ? "representante legal" : "titular"}:</Typography>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;Realizada</Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <DocumentScannerIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Leitura do documento do {(legalResponsible) ? "responsável legal" : legalRepresentant ? "representante legal" : "titular"}:</Typography>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;Realizada</Typography>
      </Grid>
      {(legalResponsible && attachmentList.length > 0) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <AttachFileIcon />
        <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >Anexo:</Typography>
      </Grid>}
      {legalResponsible && attachmentList.map(attachment => (<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Typography variant="body1" component="span" >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{attachment.name}</Typography>
      </Grid>))}
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: '10%' }}>
        <GVCheckbox
          name="dataConfirmDeclaration"
          label=""
          options={[
            { id: 1, label: <Typography variant="body1" component="span" >Declaro que todas as informações acima prestadas por mim são verdadeiras, e assumo a inteira responsabilidade legal pela veracidade delas.<GVTooltip title={"Dos Crimes Conta a Fé Pública - Da Falsidade Documental - Falsidade Ideológica : omitir, em documento público ou particular, declaração que dele devia constar, ou nele inserir ou fazer inserir declaração falsa ou diversa da que devia ser escrita, com o fim de prejudicar direito, criar obrigação ou alterar a verdade sobre fato juridicamente relevante (Art. 299 do Código Penal)."}>
            <InfoIcon fontSize="small" style={{ color: '#1351b4', marginLeft: 8 }} />
          </GVTooltip></Typography> }
          ]}
          isRequired />
      </Grid>
    </Grid>
  </React.Fragment>)
})

const mapStateToProps = state => ({

})

const mapDispatchToProps = dispatch =>
  bindActionCreators(componentActions, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StepSend);