import { Grid, Typography } from '@mui/material'
import React from 'react'
import GVFeedback from '../feedback'
import { Mexx2DOConsole } from '../../utils/mexxTalkConsole'

export default function Validation({ description }) {
    Mexx2DOConsole.log('Validation Error Screen')
    return (<Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <GVFeedback warning2>Atenção</GVFeedback>
        </Grid>
        <div style={{ paddingLeft: 16, paddingTop: 16 }} dangerouslySetInnerHTML={{ __html: description }}/>
    </Grid>)
}