export function setCreatedCalledData(data) {
  return {
    type: 'SET_CREATED_CALLED_DATA',
    calledId: data.calledId,
    createdAt: data.createdAt,
    name: data.name,
    email: data.email,
    document: data.document,
    ofieldsText: data.ofieldsText,
    backRoute: data.backRoute
  }
}

export function resetCreatedCalledData(data) {
  return {
    type: 'RESET_CREATED_CALLED_DATA'
  }
}