import React from "react";
import { Grid, Typography } from "@mui/material";
import { styled } from '@mui/styles';
import typography from '../../../../styles/typography'

const ReadDataTitle = styled(Typography)(({ theme }) => ({
  ...typography.label
}))

const CompletedFormReadData = React.memo(function CompletedFormReadData({ label, StartIcon, data }) {
  return (<Grid container spacing={0}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <ReadDataTitle fontWeight="600" style={{ display: 'flex',
    alignItems: 'center' }}>
      {StartIcon && <StartIcon style={{ fontSize: 20, marginRight: 5 }} />}
      {label}
      </ReadDataTitle>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" fontWeight="300" >{data}</Typography>
    </Grid>
    
  </Grid>)
})

export default CompletedFormReadData;