import { Mexx2DOConsole } from "../../utils/mexxTalkConsole"

const initialState = {
  hasError: false,
  attemps: 0,
  lastAttemp: null,
}

export default function faceRecognitionStep(state = initialState, action) {
  Mexx2DOConsole.log("=== Redux - faceRecognitionStep ===")
  Mexx2DOConsole.log(action)
  switch (action.type) {
    case 'SET_FACE_RECOGNITION_STEP_ERROR':
      return {
        ...state,
        hasError: action.hasError,
      }
    case 'FACE_RECOGNITION_STEP_ADD_LIMIT_ATTEMP':
      return {
        ...state,
        attemps: state.attemps+1,
        lastAttemp: new Date().getTime()
      }
    case 'FACE_RECOGNITION_STEP_RESET_LIMIT_ATTEMP':
      return {
        ...state,
        attemps: 0,
        lastAttemp: null
      }
    case 'RESET_FACE_RECOGNITION_STEP_ERROR':
      return initialState
    default:
      return state;
  }
}