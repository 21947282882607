import React from 'react';
import { BrowserRouter, Switch, Route , Redirect} from "react-router-dom";

import Page404  from './pages/page404/page404';
import Unavailable from './pages/unavailable'
import { LANGUAGES } from './_i18n/languages';
import AlterAccountEmailTest from './pages/alter-account-email-test';
import { useVwContext } from './context/vw-context';
import Disable2FactorVerificationTest from './pages/disable-2-factor-verification-test';

import WebcamCapture from './pages/cam-test';

const MultiLanguageRoute = (props) => {
  const defaultLanguage = LANGUAGES.pt.urlLang;
  const hasLang = props.computedMatch.params.lang;
  const url = props.computedMatch.url;
  const is404Page = props.path;
  const isBasePathWithoutLang = props.path === '/';
  
  var currentUrlLang = window.location.pathname.split('/')[1];
  var currentLanguage = LANGUAGES[currentUrlLang];
  if(!currentLanguage) {
    currentLanguage = LANGUAGES[LANGUAGES.default];
  }
 

  if(isBasePathWithoutLang)  return  <Redirect to={`/${defaultLanguage}/login`} />
  if(!hasLang && !is404Page)

  var path = `${url}`;
  if(hasLang == url || '/'+hasLang == url){
     path = `/${currentLanguage.urlLang}${url}`;
  }
  return <Route language={currentLanguage.urlLang} {...props} />    
}

const VWMultiLanguageRoute = (props) => {
  const { handleToggleVwClass } = useVwContext()

  handleToggleVwClass(props.enableVW)

  return <MultiLanguageRoute {...props} />
}

export default function Routes() {
  return (
    <BrowserRouter forceRefresh={false}>
      <Switch>
        <VWMultiLanguageRoute enableVW={false} path="/" exact component={AlterAccountEmailTest} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/" exact component={AlterAccountEmailTest} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/alterar-email-v2" exact component={AlterAccountEmailTest} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/desativar-2fa-v2" exact component={Disable2FactorVerificationTest} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/unavailable" exact component={Unavailable} />
        <VWMultiLanguageRoute enableVW={false} path="/:lang/cam-test" exact component={WebcamCapture} />
        <VWMultiLanguageRoute enableVW={false} path="*" component={Page404}/>
      </Switch>
    </BrowserRouter>
  );
}