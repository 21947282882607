import React, { useCallback, useState } from 'react'
import Webcam from 'react-webcam'
import './styles.css'

const FACING_MODE_USER = { exact: "user" }
const FACING_MODE_ENVIRONMENT = { exact: "environment" }

const videoConstraints = {
  facingMode: FACING_MODE_USER
}

const WebcamCapture = () => {
  const [facingMode, setFacingMode] = useState(FACING_MODE_USER)

  const handleClick = useCallback(() => {
    setFacingMode(
      prevState =>
        prevState === FACING_MODE_USER
          ? FACING_MODE_ENVIRONMENT
          : FACING_MODE_USER
    )
  }, [])

  return (
    <div className='container'>
      <button onClick={handleClick}>Switch camera</button>
      <Webcam
        audio={false}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          ...videoConstraints,
          facingMode
        }}
      />
    </div>
  )
}

export default WebcamCapture