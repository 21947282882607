import { Grid, Typography } from '@mui/material'
import React from 'react'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import GVFeedback from '../feedback'

export default function PEP({ document }) {
  return (<Grid container spacing={2}>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <GVFeedback warning2>Atenção</GVFeedback>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" fontWeight="bold">Este CPF faz parte do grupo de Pessoas Expostas Politicamente (PEP)</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <AssignmentIndIcon />
      <Typography variant="body1" component="span" fontWeight="bold" style={{ paddingLeft: '10px' }} >{document}</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" >Por questões de segurança, não será possível prosseguir com este atendimento através deste formulário.</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" >Acesse nosso chat e identifique-se como Pessoa Exposta Politicamente para prosseguir com seu atendimento.</Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Typography variant="body1" component="span" fontWeight="bold">Horário de atendimento: Segunda a sexta, das 8:00 às 18:00.</Typography>
    </Grid>
  </Grid>)
}