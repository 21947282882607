import * as React from 'react';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import HomeIcon from '@mui/icons-material/Home';
import { styled } from '@mui/styles';
import { Card, CardContent } from '@mui/material';

const GVBRCard = styled(Card)(({ theme }) => ({
  '--card-padding': 'var(--spacing-scale-2x)',
  '--card-height-fixed': '250px',
  background: 'var(--background)',
  boxShadow: 'var(--surface-shadow-sm)',
  color: 'var(--color)',
  marginBottom: 'var(--spacing-scale-2x)',
}))

export default function GVCard(props) {
  return (<GVBRCard style={props.cardStyle ? props.cardStyle : {}}>
    <CardContent style={props.contentStyle ? props.contentStyle : {}}>
      {props.children}
    </CardContent>
  </GVBRCard>);
}