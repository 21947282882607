const initialState = {
  trigger: false,
};

const triggers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TRIGGER_RESET_STEPS':
      return {
        trigger: true
      };
    case 'RESET_TRIGGER_RESET_STEPS':
      return {
        trigger: false
      };
    default:
      return state;
  }
};

export default triggers;