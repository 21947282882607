export function openDialogDocumentRecognitionStep(isOpen) {
  return {
      type: 'OPEN_DIALOG_DOCUMENT_RECOGNITION_STEP',
      isOpen,
  }
}

export function nextDialogDocumentRecognitionStep() {
  return {
      type: 'NEXT_DIALOG_DOCUMENT_RECOGNITION_STEP'
  }
}

export function completeDialogDocumentRecognitionStep(isCompleted) {
  return {
      type: 'COMPLETE_DIALOG_DOCUMENT_RECOGNITION_STEP',
      isCompleted,
  }
}

export function resetDataDialogDocumentRecognitionStep() {
  return {
      type: 'RESET_DATA_DIALOG_DOCUMENT_RECOGNITION_STEP'
  }
}