import { palette } from './palette';
import './colors/vivid/red-warm-vivid.css'
import './colors/default/gray.css';
import './fonts/font-family.css'
import './fonts/font-size.css'
import './fonts/font-weight.css'
import './fonts/line-height.css'
import './spacing/scale.css';

export default {
  fontFamily: 'var(--font-family-base)',
  h1: {
    fontSize: 'var(--font-size-scale-up-06)',
    fontWeight: 'var(--font-weight-light)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-4x)'
  },
  h1Grid: {
    fontSize: 'var(--font-size-scale-up-04)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2xh)'
  },
  h2: {
    fontSize: 'var(--font-size-scale-up-05)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2xh)',
    marginTop: 'var(--spacing-scale-3xh)',
  },
  h2Grid: {
    fontSize: 'var(--font-size-scale-up-03)',
    fontWeight: 'var(--font-weight-semi-bold)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2xh)',
    marginTop: 'var(--spacing-scale-3xh)',
  },
  h3: {
    fontSize: 'var(--font-size-scale-up-04)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2xh)',
    marginTop: 'var(--spacing-scale-3xh)',
  },
  h3Grid: {
    fontSize: 'var(--font-size-scale-up-02)',
    fontWeight: 'var(--font-weight-bold)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2xh)',
    marginTop: 'var(--spacing-scale-3xh)',
  },
  h4: {
    fontSize: 'var(--font-size-scale-up-03)',
    fontWeight: 'var(--font-weight-semi-bold)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2xh)',
    marginTop: 'var(--spacing-scale-3xh)',
  },
  h4Grid: {
    fontSize: 'var(--font-size-scale-up-01)',
    fontWeight: 'var(--font-weight-bold)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2xh)',
    marginTop: 'var(--spacing-scale-2x)',
  },
  h5: {
    fontSize: 'var(--font-size-scale-up-02)',
    fontWeight: 'var(--font-weight-bold)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2x)',
    marginTop: 'var(--spacing-scale-3xh)',
    textTransform: 'uppercase'
  },
  h5Grid: {
    fontSize: 'var(--font-size-scale-base)',
    fontWeight: 'var(--font-weight-extra-bold)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2x)',
    marginTop: 'var(--spacing-scale-2x)',
    textTransform: 'uppercase'
  },
  h6: {
    fontSize: 'var(--font-size-scale-up-01)',
    fontWeight: 'var(--font-weight-extra-bold)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2x)',
    marginTop: 'var(--spacing-scale-3xh)',
    textTransform: 'uppercase'
  },
  h6Grid: {
    fontSize: 'var(--font-size-scale-down-01)',
    fontWeight: 'var(--font-weight-extra-bold)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2x)',
    marginTop: 'var(--spacing-scale-2x)',
    textTransform: 'uppercase'
  },
  p: {
    fontSize: 'var(--font-size-scale-up-01)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--font-lineheight-medium)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2x)'
  },
  pGrid: {
    fontSize: 'var(--font-size-scale-base)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--font-lineheight-medium)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2x)'
  },
  label: {
    fontSize: 'var(--font-size-scale-base)',
    fontWeight: 'var(--font-weight-semi-bold)',
    lineHeight: 'var(--font-lineheight-medium)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-half)'
  },
  input: {
    fontSize: 'var(--font-size-scale-up-01)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-half)'
  },
  placeholder: {
    fontSize: 'var(--font-size-scale-base)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--font-lineheight-medium)',
    fontStyle: 'italic',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-half)',
    marginTop: 'var(--spacing-scale-half)'
  },
  legend: {
    fontSize: 'var(--font-size-scale-up-01)',
    fontWeight: 'var(--font-weight-bold)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2x)',
    marginTop: 'var(--spacing-scale-2x)'
  },
  mark: {
    color: palette.reading,
    background: 'var(--red-warm-vivid-10)',
  },
  code: {
    fontSize: 'var(--font-size-scale-base)',
    fontWeight: 'var(--font-weight-medium)',
    lineHeight: 'var(--font-lineheight-low)',
    color: palette.reading,
    background: 'var(--gray-5)',
    paddingInline: 'var(--spacing-scale-half)',
    paddingBlock: 'var(--spacing-scale-2x)'
  },
  list: {
    fontSize: 'var(--font-size-scale-up-01)',
    fontWeight: 'var(--font-weight-regular)',
    lineHeight: 'var(--font-lineheight-medium)',
    color: palette.reading,
    marginBottom: 'var(--spacing-scale-2x)'
  },
  listItem: {
    marginBottom: 'var(--spacing-scale-base)'
  }
};
