export function openDialogAlterEmailStepForMineConfirmEmail(isOpen) {
  return {
      type: 'ALTER_EMAIL_OPEN_DIALOG_STEP_FOR_MINE_CONFIRM_EMAIL',
      isOpen,
  }
}

export function completeDialogAlterEmailStepForMineConfirmEmail(isCompleted) {
  return {
      type: 'ALTER_EMAIL_COMPLETE_DIALOG_STEP_FOR_MINE_CONFIRM_EMAIL',
      isCompleted,
  }
}

export function addLastEmailAlterEmailStepForMineConfirmEmail(lastEmail) {
  return {
      type: 'ALTER_EMAIL_ADD_LAST_EMAIL_STEP_FOR_MINE_CONFIRM_EMAIL',
      lastEmail,
  }
}

export function addEmailLimitAlterEmailStepForMineConfirmEmail() {
  return {
      type: 'ALTER_EMAIL_ADD_EMAIL_LIMIT_STEP_FOR_MINE_CONFIRM_EMAIL'
  }
}

export function resetEmailLimitAlterEmailStepForMineConfirmEmail() {
  return {
      type: 'ALTER_EMAIL_RESET_EMAIL_LIMIT_STEP_FOR_MINE_CONFIRM_EMAIL'
  }
}

export function resetDataAlterEmailStepForMineConfirmEmail() {
  return {
      type: 'ALTER_EMAIL_RESET_DATA_STEP_FOR_MINE_CONFIRM_EMAIL'
  }
}