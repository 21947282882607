import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { FormControl, IconButton, RadioGroup, useMediaQuery } from '@mui/material';
import Radio, { radioClasses } from '@mui/material/Radio'
import FormControlLabel, { formControlLabelClasses } from '@mui/material/FormControlLabel'
import FormHelperText, { formHelperTextClasses } from '@mui/material/FormHelperText'
import FormLabel, { formLabelClasses } from '@mui/material/FormLabel'
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';
import '../../../App.css'
import './styles.css'
import typography from '../../../styles/typography'
import GVFormHelperText from '../form-helper-text';
import GVFeedback from '../../feedback';
import GVTooltip from '../../tooltip';
import InfoIcon from '@mui/icons-material/Info';
import theme from '../../../styles/theme'

const BpIcon = styled('span')(({ theme }) => ({
  background: 'var(--background)',
  border: '1px solid var(--border-color)',
  borderRadius: '50%',
  content: '""',
  height: 'var(--radio-size)',
  left: '0',
  position: 'absolute',
  top: '-1px',
  width: 'var(--radio-size)',
  'input:hover ~ &': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  }
}));

const BpCheckedIcon = styled(BpIcon)({
  '&::before': {
    background: 'var(--selected)',
    border: '7px solid var(--selected)',
    borderRadius: '50%',
    content: '""',
    height: 'var(--radio-bullet-size)',
    left: 'var(--radio-bullet-position)',
    position: 'absolute',
    right: '0',
    top: '2px',
    display: 'block',
    //top: 'calc(var(--radio-bullet-position) - 1px)',
    width: 'var(--radio-bullet-size)',
  },
  'input:hover ~ &': {
    backgroundImage: 'linear-gradient(rgba(var(--interactive-rgb), var(--hover)), rgba(var(--interactive-rgb), var(--hover)))'
  },
});

const BpRadio = styled(Radio)(({ theme }) => ({
  [`&.${radioClasses.root}.Mui-focusVisible span`]: {
    borderColor: 'var(--focus) !important',
    boxShadow: '0 0 0 var(--surface-width-md) var(--focus)',
    outline: 'none',
  }
}));

function GVBRRadio(props) {
  return (
    <BpRadio
      disableRipple
      color="default"
      icon={<BpIcon />}
      checkedIcon={<BpCheckedIcon />}
      {...props}
    />
  );
}

const GVBRFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  [`&.${formControlLabelClasses.root} `]: {
    marginTop: '8px',
    alignItems: 'flex-start'
  },
  [`& .${formControlLabelClasses.label} `]: {
    ...typography.label,
    cursor: 'pointer',
    display: 'inline-block',
    marginBottom: '0',
    minHeight: 'var(--radio-size)',
    minWidth: 'var(--radio-size)',
    paddingLeft: 'calc(var(--radio-size) / 1.5)',
    position: 'relative',
    fontWeight: 'var(--font-weight-medium)',
  },
  [`& .${formControlLabelClasses.focus} `]: {
    borderColor: 'var(--focus) !important',
    boxShadow: '0 0 0 var(--surface-width-md) var(--focus)',
    outline: 'none',
  }
}))

const GVBRFormLabel = styled(FormLabel)(({ theme }) => ({
  ...typography.label,
  marginLeft: 0,
  paddingLeft: 0,
  marginBottom: '0!important'
}))

const GVRadioButton = React.memo(function GVRadioButton({ name, label, options, isRequired, errorMessage, StartIcon }) {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const methods = useFormContext();
  const { control, formState: { errors }, clearErrors } = methods
  const formValues = methods.watch()
  
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl
      required={isRequired}
      error={Boolean(errors && errors[name])}
      component="fieldset"
      variant="standard"
    >
      <GVBRFormLabel component="legend">
        {StartIcon && <StartIcon style={{ fontSize: 20, marginRight: 5 }} />}
        {label}
      </GVBRFormLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: errorMessage ? errorMessage : 'Selecione uma opção' }}
        defaultValue=""
        render={({ field }) => (
          <RadioGroup
            {...field}
            style={{ marginLeft: 11 }}
            onChange={(e) => {
              field.onChange(e)
              clearErrors(name)
            }} >
            {options.map((option) => (
              <GVBRFormControlLabel
                key={option.id}
                control={<GVBRRadio />}
                label={<div style={{ display: 'flex', alignItems: 'center' }}>
                  {option.name}
                  {(option.name === "Para uma criança ou adolescente, sou o responsável legal" && !isMobile) && (
                    <GVTooltip title={"Segundo o estatudo da Criança e do Adolescente, Lei nº 8.069, de 13 de julho de 1990, considera-se criança, a pessoa até doze anos de idade incompletos, e adolescente aquela entre doze e dezoito anos de idade."}>
                      <InfoIcon fontSize="small" style={{ color: '#1351b4', marginLeft: 8 }} />
                    </GVTooltip>
                  )}
                  {(option.name === "Para uma criança ou adolescente, sou o responsável legal" && isMobile) && (
                    <GVTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={handleTooltipClose}
                      onOpen={handleTooltipOpen}
                      open={open}
                      disableFocusListener={true}
                      disableHoverListener={true}
                      disableTouchListener={true}
                      title={"Segundo o estatudo da Criança e do Adolescente, Lei nº 8.069, de 13 de julho de 1990, considera-se criança, a pessoa até doze anos de idade incompletos, e adolescente aquela entre doze e dezoito anos de idade."}>
                      <IconButton onClick={handleTooltipOpen}>
                        <InfoIcon fontSize="small" style={{ color: '#1351b4' }} />
                      </IconButton>
                    </GVTooltip>
                  )}
                </div>}
                value={`${option.id}`}
              />
            ))}
          </RadioGroup>
        )}
      />
      {isRequired && errors && errors[name] && (
        <GVFeedback danger>{errors[name].message}</GVFeedback>
      )}
    </FormControl>
  );
})

GVRadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  isRequired: PropTypes.bool,
  errorMessage: PropTypes.string,
  StartIcon: PropTypes.elementType
};

export default GVRadioButton;
